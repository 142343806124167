"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store_etrf_filters = exports.FILTER_ACTIONS = void 0;
exports.FILTER_ACTIONS = {
    ETRF_FILTERS: 'ETRF_FILTERS',
};
var store_etrf_filters = function (filters) {
    console.log(filters, 'filters in filter actions');
    return {
        type: exports.FILTER_ACTIONS.ETRF_FILTERS,
        payload: filters,
    };
};
exports.store_etrf_filters = store_etrf_filters;

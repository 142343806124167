"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDuplicatevalues = exports.covertKeyValuesReasonsList = exports.covertKeyValuesWorkingHours = exports.modifiedslots = exports.getLatAndLng = exports.arrayofData = exports.isFieldValueEmptyForCRMname = exports.isFieldValueEmpty = exports.concatFirstNameLastName = exports.filterLabCodeData = exports.filterAuditorsList = exports.filterAuditeeList = exports.filterDataByLabelInAudit = exports.filterAuditorsArray = exports.filterAuditeesArray = exports.filterDataArrByLabel = exports.filterDataByLabel = exports.filterDataVendorIds = exports.filterData = exports.convertLabelAndValueFEList = exports.convertLabelAndValueLabCode = exports.convertLabelAndValueCity = exports.convertLabelAndValueCountries = exports.convertLabelAndValueStates = exports.convertZonesDropdwonOptionFromConfig = exports.convertVehicleTypeDropdownOptionFromConfig = exports.covertTenureDropdownOptionsFromConfig = exports.convertUserListDropdownOptionsFormConfig = exports.covertCompanyDropdownOptionsFromConfig = exports.convertVendorOptionFromConfig = exports.convertVisitTypesFromConfig = exports.convertVisitStatusFromConfig = exports.covnertCashReportStatusFromConfig = exports.convertGenderOptionFromConfig = exports.convertInventoryoptionFromConfig = exports.convertRejectTypelistFromResponse = exports.convertLabelAndValueToConfig = exports.convertFEIdnOptionFromConfig = exports.convertLabelAndValue = exports.shipmentNormaldateandTimeFormatte = exports.etrfdateandTimeFormatte = exports.normaldateandTimeFormatte = exports.updateddateFormatte = exports.normaldateFormatte = exports.visitsListDateTimeFormatter = exports.dateTimeFormatterWithSeconds = exports.shipmentDateTimeFormatter = exports.dateTimeFormatter = exports.visitDateFormatter = exports.dateFormatter = void 0;
exports.convertReportStatusDropdownOptionFromConfig = exports.convertRouteAssignmentTypeFromRes = exports.convertUserRoleListFromRes = exports.convertUsersListFromRes = exports.convertWareHouseResponse = exports.isValueEmpty = exports.filterSelectedVisitList = exports.filterFE_IdsForHeatMap = exports.featureCheck = exports.getAccessibleFeatures = exports.convertRoleListFromRes = exports.convertVendorsListFromres = exports.disableTabs = exports.moduleFeatures = exports.convertIntoNumber = exports.isFieldValueTrueFalse = exports.convertRejectedFEListFromRes = exports.convertLeavesListFromRes = exports.convertActiveFEListFromRes = exports.getFeaturesList = exports.convertFE_Types_From_Config = exports.flattenObj = exports.getVisitTypeFromID = exports.getVisitStatusFromID = exports.convertDownloadReportVisitsFromRes = exports.convertVisitsFromRes = exports.getAssignmentStatusFromId = exports.trueFalseCoverter = exports.ondemandKey = exports.preciousSampleKey = exports.filterUnassiedVisitsData = exports.filterUniqueFEData = exports.approvalsResponseConverter = exports.trueFalseToYesNo = exports.mapAddressList = exports.filterSelectedStateList = exports.convertOperatingPlaces = exports.convertOperatingLabelCity = exports.convertOperatingLabelState = exports.convertAuditStatusListFromRes = exports.convertHubList = exports.convertHubListFromRes = exports.convertFEListFromResponse = exports.convertCCListFromResponse = exports.deletedDataList = exports.removeDuplicateCity = exports.removeDuplicateStates = exports.removeDuplicateObjectInCity = exports.removeDuplicateObject = exports.removeDuplicateColors = void 0;
exports.convertFeAttendanceReportListFromRes = exports.convertUnavailableFEListFromRes = exports.convertOndemandPickupsListFromRes = exports.resticSubRoute = exports.compareModulesToMenuOrder = exports.convertHubListFromResponse = exports.routeResponseConverter = exports.NotificationFreq = exports.NotificationCat = exports.convertNotificationCategoriesFromRes = exports.selecteAllOption = exports.concatName = exports.convertAllVisitsListFromRes = exports.convertLeaveListFromRes = exports.convertETRFStatus = exports.getVisitTypeFromIDs = exports.getVisitStatusFromIDs = exports.covertMasterBoxStatusFromConfig = exports.convertVisitStatus = exports.capitalize = exports.ondemandvisittype = exports.dynamic_route_plan_center_types = exports.static_route_plan_center_types = exports.static_route_plan_assignment_types = exports.AxStatusKey = exports.convertLabDataResponse = exports.getCurrentday = exports.convertIntoIdLabel = exports.convertDownloadableExcelDataFromRes = exports.convertRoleDataResponse = exports.restictSpecialCharecters = exports.convertLabcodeResponse = exports.convertDayIDIntoId = exports.convertDayIntoDayid = exports.downloadExcel = exports.dateFormateYMD = exports.filterFeatures = exports.getAccessibleRoutesForSSOReports = exports.getAccessibleRoutes = exports.concatenateLabCodeWithAddress = exports.convertLabelAndValueCCList = exports.convertCCListResponse = exports.convertUserDatails = exports.convertUserLogisticsListFromRes = exports.convertCREListFromRes = exports.createPayloadForVendorList = exports.createPayloadFEForApprovalPending = exports.createPayloadForOnLeaveToday = exports.createPayloadForFEList = exports.convertReportTypeDropdownOptionFromConfig = void 0;
exports.convertToISDDateFormat = exports.convertCompanyConfigResponse = exports.convertFEListResponse = exports.convertFEInfoResponse = exports.convertVisitsInfoResponse = exports.convertUltraStaticFEResponse = exports.convertUltraStaticFEList = exports.convertUltraStaticCCList = exports.convertETRFFEList = exports.convertETRFResponse = exports.sortObjectKeys = exports.isValueZero = exports.convertAdditAnswerListFromRes = exports.staticdata = exports.ReplacetrueFalseCoverter = exports.HeadingBasedOnEvent = exports.convertOntimePickXaxis = exports.convertIntracityXaxis = exports.ShipmentTat = exports.dateFormatterForMIS = exports.processedkey = exports.UpdateHistory = exports.hourmin = exports.clientSlotwiseReportResponseConverter = exports.flattenObjForReports = exports.convertUsersListMappingFromRes = exports.convertNothingToPickupReportListFromRes = exports.convertOutstationMasterboxReportListFromRes = void 0;
var loadsh = require("lodash");
var moment = require("moment");
var XLSX = require("xlsx");
var auto_routing_helper_1 = require("../components/super_admin/control_dashboard/auto_routing_helper");
var cre_api_calls_1 = require("../components/super_admin/CRE/cre_api_calls");
var appConstants_1 = require("../constants/appConstants");
var FECreationTabConstant_1 = require("../constants/FECreationTabConstant");
var route_path_1 = require("../constants/route_path");
var menu_constants_1 = require("../menuList/menu_constants");
var routes_list_1 = require("../routes/routes_list");
var store_1 = require("../store");
var lodash_1 = require("./../utils/lodash");
var dateFormatter = function (params) {
    var value = '--';
    return params
        ? moment(params.split('T')[0]).format('DD-MM-YYYY')
        : value;
};
exports.dateFormatter = dateFormatter;
var visitDateFormatter = function (value) {
    return value ? moment(value).format(appConstants_1.fixedDateFormatt) : 'NA';
};
exports.visitDateFormatter = visitDateFormatter;
var dateTimeFormatter = function (params) {
    var value = '--';
    return params
        ? moment(params).format("".concat(appConstants_1.fixedDateFormatt, "     hh:mm A"))
        : value;
};
exports.dateTimeFormatter = dateTimeFormatter;
var shipmentDateTimeFormatter = function (params) {
    var value = '--';
    var converteddate = moment(params);
    converteddate.subtract(5, 'hours').toDate(), 'hours';
    converteddate.subtract(30, 'minutes').toDate(), 'minutes';
    return params ? moment(converteddate).format('DD-MM-YYYY hh:mm A') : value;
};
exports.shipmentDateTimeFormatter = shipmentDateTimeFormatter;
var dateTimeFormatterWithSeconds = function (params) {
    var value = '--';
    return params
        ? moment(params).format("".concat(appConstants_1.fixedDateFormatt, "     hh:mm:ss A"))
        : value;
};
exports.dateTimeFormatterWithSeconds = dateTimeFormatterWithSeconds;
var visitsListDateTimeFormatter = function (value) {
    return value ? moment(value).format("".concat(appConstants_1.fixedDateFormatt, "     hh:mm A")) : 'NA';
};
exports.visitsListDateTimeFormatter = visitsListDateTimeFormatter;
var normaldateFormatte = function (date) {
    return moment(date).format(appConstants_1.fixedDateFormatt);
};
exports.normaldateFormatte = normaldateFormatte;
var updateddateFormatte = function (date) {
    return moment(date).format(appConstants_1.UpdatedDateFormat);
};
exports.updateddateFormatte = updateddateFormatte;
var normaldateandTimeFormatte = function (date) {
    return moment(date).format("".concat(appConstants_1.fixedDateFormatt, " hh:mm A"));
};
exports.normaldateandTimeFormatte = normaldateandTimeFormatte;
var etrfdateandTimeFormatte = function (date) {
    return moment(date).format("".concat(appConstants_1.fixedDateFormatt, " hh:mm:ss A"));
};
exports.etrfdateandTimeFormatte = etrfdateandTimeFormatte;
var shipmentNormaldateandTimeFormatte = function (date) {
    var converteddate = moment(date);
    converteddate.subtract(5, 'hours').toDate(), 'hours';
    converteddate.subtract(30, 'minutes').toDate(), 'minutes';
    return moment(converteddate).format('DD-MM-YYYY hh:mm A');
};
exports.shipmentNormaldateandTimeFormatte = shipmentNormaldateandTimeFormatte;
var convertLabelAndValue = function (response) {
    var feTypesLabelAndValue = [];
    for (var id in response) {
        if (id) {
            feTypesLabelAndValue.push({
                label: response[id],
                value: parseInt(id),
                id: parseInt(id),
            });
        }
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValue = convertLabelAndValue;
var convertFEIdnOptionFromConfig = function (response) {
    var FEexitIdLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            FEexitIdLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return FEexitIdLabelAndValue;
};
exports.convertFEIdnOptionFromConfig = convertFEIdnOptionFromConfig;
var convertLabelAndValueToConfig = function (response) {
    var feTypesLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            feTypesLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueToConfig = convertLabelAndValueToConfig;
var convertRejectTypelistFromResponse = function (response) {
    var rejectTypeLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            rejectTypeLabelAndValue.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return rejectTypeLabelAndValue;
};
exports.convertRejectTypelistFromResponse = convertRejectTypelistFromResponse;
var convertInventoryoptionFromConfig = function (response) {
    var inventoryOptions = [];
    response.forEach(function (each, i) {
        inventoryOptions.push({
            label: each.item.toUpperCase(),
            value: each.id,
            id: each.id,
        });
    });
    return inventoryOptions;
};
exports.convertInventoryoptionFromConfig = convertInventoryoptionFromConfig;
var convertGenderOptionFromConfig = function (response) {
    var genderTypeLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            genderTypeLabelAndValue.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return genderTypeLabelAndValue;
};
exports.convertGenderOptionFromConfig = convertGenderOptionFromConfig;
var covnertCashReportStatusFromConfig = function (response) {
    var cashStatusLabelnValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            cashStatusLabelnValue.push({
                label: (0, exports.capitalize)(response[each].toString().replaceAll('_', '  ').toLowerCase()),
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return cashStatusLabelnValue;
};
exports.covnertCashReportStatusFromConfig = covnertCashReportStatusFromConfig;
var convertVisitStatusFromConfig = function (response) {
    var visitStatusLabelnValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            visitStatusLabelnValue.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return visitStatusLabelnValue;
};
exports.convertVisitStatusFromConfig = convertVisitStatusFromConfig;
var convertVisitTypesFromConfig = function (response) {
    var visitTypeLabelnValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            visitTypeLabelnValue.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return visitTypeLabelnValue;
};
exports.convertVisitTypesFromConfig = convertVisitTypesFromConfig;
var convertVendorOptionFromConfig = function (response) {
    var vendorTypeLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            vendorTypeLabelAndValue.push({
                id: parseInt(each),
                name: response[each],
                code: response[each],
                axapta_code: response[each],
            });
        });
    }
    return vendorTypeLabelAndValue;
};
exports.convertVendorOptionFromConfig = convertVendorOptionFromConfig;
var covertCompanyDropdownOptionsFromConfig = function (response) {
    var companyLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            companyLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return companyLabelAndValue;
};
exports.covertCompanyDropdownOptionsFromConfig = covertCompanyDropdownOptionsFromConfig;
var convertUserListDropdownOptionsFormConfig = function (response) {
    var userListOptions = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            userListOptions.push({
                label: response[each],
                value: response[each],
                id: response[each],
            });
        });
    }
    return userListOptions;
};
exports.convertUserListDropdownOptionsFormConfig = convertUserListDropdownOptionsFormConfig;
var covertTenureDropdownOptionsFromConfig = function (response) {
    var tenureLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            tenureLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return tenureLabelAndValue;
};
exports.covertTenureDropdownOptionsFromConfig = covertTenureDropdownOptionsFromConfig;
var convertVehicleTypeDropdownOptionFromConfig = function (response) {
    var vehicleTypeLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            vehicleTypeLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return vehicleTypeLabelAndValue;
};
exports.convertVehicleTypeDropdownOptionFromConfig = convertVehicleTypeDropdownOptionFromConfig;
var convertZonesDropdwonOptionFromConfig = function (response) {
    var zoneLabelAndValue = [];
    var uniqueRegionalManagers = lodash_1.default.uniqBy(response, 'regional_manager1');
    uniqueRegionalManagers.forEach(function (each) {
        zoneLabelAndValue.push({
            id: each.id,
            label: "".concat(each.regional_manager1, ", ").concat(each.zone),
            value: each.id,
            regional_manager: each.regional_manager1,
        });
    });
    return zoneLabelAndValue;
};
exports.convertZonesDropdwonOptionFromConfig = convertZonesDropdwonOptionFromConfig;
var convertLabelAndValueStates = function (response) {
    var feTypesLabelAndValue = [];
    var stateAscending = __spreadArray([], response, true).sort(function (a, b) {
        return a.state_name.localeCompare(b.state_name);
    });
    for (var _i = 0, stateAscending_1 = stateAscending; _i < stateAscending_1.length; _i++) {
        var data = stateAscending_1[_i];
        feTypesLabelAndValue.push({
            id: data.id,
            label: data.state_name,
            short_code: data.short_code,
            value: data.id,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueStates = convertLabelAndValueStates;
var convertLabelAndValueCountries = function (response) {
    var feTypesLabelAndValue = [];
    for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
        var data = response_1[_i];
        feTypesLabelAndValue.push({
            id: parseInt(data.id.toString()),
            value: parseInt(data.id.toString()),
            label: data.country_name,
            short_code: data.country_code,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueCountries = convertLabelAndValueCountries;
var convertLabelAndValueCity = function (response) {
    var feTypesLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        feTypesLabelAndValue.push({
            id: data.id,
            label: "".concat(data.city_name, ", ").concat(data.state.state_name),
            value: data.id,
            short_code: data.city_code,
            state_name: data.state.state_name,
            state_id: data.state.id,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueCity = convertLabelAndValueCity;
var convertLabelAndValueLabCode = function (response) {
    var feTypesLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        if (data.lab_code) {
            feTypesLabelAndValue.push({
                id: data.id,
                value: data.lab_code ? data.lab_code.toString() : '',
                label: data.lab_code ? data.lab_code.toString() : '',
                center_name: data.lab_name ? data.lab_name : '',
                center_state: data.lab_state ? data.lab_state : '',
                center_city: data.lab_city ? data.lab_city : '',
                warehouse_code: data.warehouse_code ? data.warehouse_code : '',
                lab_latittude: data.latitude,
                lab_longittude: data.longitude,
            });
        }
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueLabCode = convertLabelAndValueLabCode;
var convertLabelAndValueFEList = function (response) {
    var _a;
    var feTypesLabelAndValue = [];
    for (var _i = 0, _b = response && response; _i < _b.length; _i++) {
        var data = _b[_i];
        feTypesLabelAndValue.push({
            id: data.id,
            value: data.id,
            label: "".concat(data.first_name || '', " ").concat(data.last_name || '', ",").concat(data.mobile || '', ", ").concat(((_a = data === null || data === void 0 ? void 0 : data.lab) === null || _a === void 0 ? void 0 : _a.lab_code) || '', " ").concat(data.city_name || ''),
            fe_type: data.fe_type,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertLabelAndValueFEList = convertLabelAndValueFEList;
var filterData = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.id === parseInt(params.id); })[0];
    return filteredOption;
};
exports.filterData = filterData;
var filterDataVendorIds = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.label === params.id; })[0];
    return filteredOption;
};
exports.filterDataVendorIds = filterDataVendorIds;
var filterDataByLabel = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.label === params.name; })[0];
    return filteredOption;
};
exports.filterDataByLabel = filterDataByLabel;
var filterDataArrByLabel = function (params) {
    var filteredOptions = loadsh.filter(params.filterArray, function (arrayItem) { return params.namearr.includes(arrayItem.label); });
    return filteredOptions;
};
exports.filterDataArrByLabel = filterDataArrByLabel;
var filterAuditeesArray = function (params) {
    var filteredOptions = loadsh.filter(params.filterArray, function (arrayItem) { return params.namearr.includes(arrayItem.short_code); });
    return filteredOptions;
};
exports.filterAuditeesArray = filterAuditeesArray;
var filterAuditorsArray = function (params) {
    var filteredOptions = loadsh.filter(params.filterArray, function (arrayItem) { return params.namearr.includes(arrayItem.FE_id); });
    return filteredOptions;
};
exports.filterAuditorsArray = filterAuditorsArray;
var filterDataByLabelInAudit = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.label === params.name; })[0];
    return filteredOption;
};
exports.filterDataByLabelInAudit = filterDataByLabelInAudit;
var filterAuditeeList = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.short_code === params.name; })[0];
    return filteredOption;
};
exports.filterAuditeeList = filterAuditeeList;
var filterAuditorsList = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.FE_id === params.name; })[0];
    return filteredOption;
};
exports.filterAuditorsList = filterAuditorsList;
var filterLabCodeData = function (params) {
    var filteredOption = loadsh.filter(params.filterArray, function (arrayItem) { return arrayItem.value == params.id; })[0];
    return filteredOption;
};
exports.filterLabCodeData = filterLabCodeData;
var concatFirstNameLastName = function (first_name, last_name, mobile) {
    var feFullName = '';
    if (!first_name && !last_name) {
        return feFullName;
    }
    if (first_name) {
        feFullName += first_name;
    }
    if (last_name) {
        feFullName += " ".concat(last_name);
    }
    if (mobile) {
        feFullName += " ".concat(mobile);
    }
    return feFullName;
};
exports.concatFirstNameLastName = concatFirstNameLastName;
var isFieldValueEmpty = function (fieldValue) {
    var value = '--';
    return fieldValue ? fieldValue : value;
};
exports.isFieldValueEmpty = isFieldValueEmpty;
var isFieldValueEmptyForCRMname = function (fieldValue) {
    var value = '--';
    return fieldValue.toString().trim() ? fieldValue : value;
};
exports.isFieldValueEmptyForCRMname = isFieldValueEmptyForCRMname;
var arrayofData = function (fieldValue) {
    var value = '--';
    var data;
    return fieldValue
        ? fieldValue.map(function (e) {
            data = e;
            return "".concat(data, ", ").concat('');
        })
        : value;
};
exports.arrayofData = arrayofData;
var getLatAndLng = function (lat, lng) {
    var value = '--';
    return lat && lng ? "".concat(lat, ",").concat(lng) : value;
};
exports.getLatAndLng = getLatAndLng;
var modifiedslots = function (lat, lng) {
    var value = '--';
    return lat && lng ? "".concat(lat, " --> ").concat(lng) : value;
};
exports.modifiedslots = modifiedslots;
var covertKeyValuesWorkingHours = function (response) {
    var workingHoursList = [];
    for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
        var data = response_2[_i];
        workingHoursList.push({
            id: data.user_id,
            feName: "".concat(data.first_name, " ").concat(data.last_name),
            weekdays: data.day ? data.day : '',
            reason_for_blocking: data.reason_for_blocking
                ? data.reason_for_blocking
                : '',
            slots: data.slots ? data.slots : [],
            createdBy: '',
            updatedDate: null,
        });
    }
    return workingHoursList;
};
exports.covertKeyValuesWorkingHours = covertKeyValuesWorkingHours;
var covertKeyValuesReasonsList = function (response) {
    var workingHoursList = [];
    for (var _i = 0, response_3 = response; _i < response_3.length; _i++) {
        var data = response_3[_i];
        workingHoursList.push({
            id: data.id,
            label: data.reason,
        });
    }
    return workingHoursList;
};
exports.covertKeyValuesReasonsList = covertKeyValuesReasonsList;
var removeDuplicatevalues = function (list) {
    var uniqueArray = [];
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var value = list_1[_i];
        if (uniqueArray.indexOf(value) === -1) {
            uniqueArray.push(value);
        }
    }
    return uniqueArray;
};
exports.removeDuplicatevalues = removeDuplicatevalues;
var removeDuplicateColors = function (list) {
    var uniqueArray = [];
    var moduleNames = list.map(function (list) { return list.color; });
    var filtered = list.filter(function (_a, index) {
        var color = _a.color;
        return !moduleNames.includes(color, index + 1);
    });
    uniqueArray.push.apply(uniqueArray, filtered);
    return uniqueArray;
};
exports.removeDuplicateColors = removeDuplicateColors;
var removeDuplicateObject = function (list) {
    var uniqueArray = [];
    var moduleNames = list.map(function (list) { return list.name; });
    var filtered = list.filter(function (_a, index) {
        var name = _a.name;
        return !moduleNames.includes(name, index + 1);
    });
    uniqueArray.push.apply(uniqueArray, filtered);
    return uniqueArray;
};
exports.removeDuplicateObject = removeDuplicateObject;
var removeDuplicateObjectInCity = function (list) {
    var uniqueArray = [];
    var cityIds = list.map(function (list) { return list.city_id; });
    var filtered = list.filter(function (_a, index) {
        var city_id = _a.city_id;
        return !cityIds.includes(city_id, index + 1);
    });
    uniqueArray.push.apply(uniqueArray, filtered);
    return uniqueArray;
};
exports.removeDuplicateObjectInCity = removeDuplicateObjectInCity;
var removeDuplicateStates = function (list) {
    try {
        var uniqueArray_1 = [];
        list.map(function (obj) {
            var index = -1;
            index = uniqueArray_1.findIndex(function (ele) { return ele.state_id === obj.state_id; });
            if (index < 0) {
                uniqueArray_1.push(obj);
            }
        });
        return uniqueArray_1;
    }
    catch (err) {
        return [];
    }
};
exports.removeDuplicateStates = removeDuplicateStates;
var removeDuplicateCity = function (list) {
    try {
        var uniqueArray_2 = [];
        list.map(function (obj) {
            var index = -1;
            index = uniqueArray_2.findIndex(function (ele) { return ele.city_id === obj.city_id; });
            if (index < 0) {
                uniqueArray_2.push(obj);
            }
        });
        return uniqueArray_2;
    }
    catch (err) {
        return [];
    }
};
exports.removeDuplicateCity = removeDuplicateCity;
var deletedDataList = function (selectedList, totalList) {
    return selectedList.filter(function (val) {
        return totalList.indexOf(val) < 0;
    });
};
exports.deletedDataList = deletedDataList;
var convertCCListFromResponse = function (response) {
    var ccLabelAndValue = [];
    var index = 1;
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        ccLabelAndValue.push({
            id: index,
            label: "".concat(data.name, ",").concat(data.warehouse_code),
            value: data.warehouse_code,
            short_code: data.warehouse_code,
        });
        index++;
    }
    return ccLabelAndValue;
};
exports.convertCCListFromResponse = convertCCListFromResponse;
var convertFEListFromResponse = function (response) {
    var feLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        if (data.fe_id != null) {
            feLabelAndValue.push({
                id: data.id,
                label: "".concat(data.first_name, ", ").concat(data.last_name, ",").concat(data.fe_id),
                value: data.id,
                FE_id: data.fe_id,
            });
        }
    }
    return feLabelAndValue;
};
exports.convertFEListFromResponse = convertFEListFromResponse;
var convertHubListFromRes = function (response) {
    var hublistRes = (response === null || response === void 0 ? void 0 : response.hub_data)
        ? response.hub_data
        : [];
    var hubListLabelAndValue = [
        { id: 2, label: 'NA', value: '' },
    ];
    for (var _i = 0, hublistRes_1 = hublistRes; _i < hublistRes_1.length; _i++) {
        var data = hublistRes_1[_i];
        hubListLabelAndValue.push({
            id: data.hub_id,
            label: data.hub_name,
            value: data.hub_id,
        });
    }
    return hubListLabelAndValue;
};
exports.convertHubListFromRes = convertHubListFromRes;
var convertHubList = function (response) {
    var hubListLabelAndValue = [];
    for (var _i = 0, response_4 = response; _i < response_4.length; _i++) {
        var data = response_4[_i];
        hubListLabelAndValue.push({
            id: data.hub_id,
            label: data.lab_code,
            value: data.lab_code,
        });
    }
    return hubListLabelAndValue;
};
exports.convertHubList = convertHubList;
var convertAuditStatusListFromRes = function (response) {
    var auditStatusList = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            if (each != '4') {
                auditStatusList.push({
                    label: response[each],
                    value: parseInt(each),
                    id: parseInt(each),
                });
            }
        });
    }
    return auditStatusList;
};
exports.convertAuditStatusListFromRes = convertAuditStatusListFromRes;
var convertOperatingLabelState = function (response) {
    var listData = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        listData.push({
            id: data.state_id,
            label: data.state_name,
            value: data.state_id,
            short_code: data.state_name,
        });
    }
    return listData;
};
exports.convertOperatingLabelState = convertOperatingLabelState;
var convertOperatingLabelCity = function (response) {
    var listData = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        listData.push({
            id: data.city_id,
            label: data.city_name,
            value: data.city_id,
            short_code: data.city_name,
            state_id: data.state_id,
            state_name: data.state_name,
        });
    }
    return listData;
};
exports.convertOperatingLabelCity = convertOperatingLabelCity;
var convertOperatingPlaces = function (response) {
    var cityAndStateList = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        cityAndStateList.push({
            state_id: data.state_id,
            city_id: data.id,
        });
    }
    return cityAndStateList;
};
exports.convertOperatingPlaces = convertOperatingPlaces;
var filterSelectedStateList = function (list, stateId) {
    var filterData = [];
    list.map(function (listValues) {
        stateId.map(function (id) {
            listValues.id == id ? filterData.push(listValues) : '';
        });
    });
    return filterData;
};
exports.filterSelectedStateList = filterSelectedStateList;
var mapAddressList = function (response) {
    var addressList = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        addressList.push({
            id: data.id,
            label: data.address.label,
            value: data.id,
            lat: data.position.lat,
            lng: data.position.lng,
            name: data.address.label,
        });
    }
    return addressList;
};
exports.mapAddressList = mapAddressList;
var trueFalseToYesNo = function (params) {
    var value = '--';
    if (params === true) {
        return 'YES';
    }
    else if (params === false) {
        return 'NO';
    }
    else {
        return value;
    }
};
exports.trueFalseToYesNo = trueFalseToYesNo;
var approvalsResponseConverter = function (params) {
    var convertedData = [];
    params.map(function (obj) {
        var convertedObj = {};
        return ((convertedObj['id'] = obj.id),
            (convertedObj['created_date_time'] = obj.created_date_time),
            (convertedObj['fe_id'] = obj.fe_id),
            (convertedObj['mobile'] = obj.mobile),
            (convertedObj['fe_joining_type'] = obj.fe_joining_type),
            (convertedObj['name'] = (0, exports.concatFirstNameLastName)(obj.first_name, obj.last_name)),
            (convertedObj['onboard_status'] = obj.onboard_status),
            (convertedObj['updated_date_time'] = obj.updated_date_time),
            (convertedObj['vendor_name'] = obj.vendor_name),
            (convertedObj['user_type'] = obj.user_type),
            (convertedObj['is_replaced_fe'] = (0, exports.trueFalseToYesNo)(obj.is_replaced_fe)),
            (convertedObj['replacement_fe_name'] = obj.replacement_fe.fe_name),
            (convertedObj['replacement_fe_id'] = obj.replacement_fe.fe_id),
            (convertedObj['replacement_fe_mobile'] = obj.replacement_fe.fe_mobile),
            convertedData.push(convertedObj));
    });
    return convertedData;
};
exports.approvalsResponseConverter = approvalsResponseConverter;
var filterUniqueFEData = function (response) {
    var data = [];
    var uniqueFes = lodash_1.default.uniqBy(response, 'user_id');
    data = uniqueFes.map(function (val) {
        try {
            var obj = {
                fe_code: val.fe_user_id,
                fe_reached_date_time: val.fe_reached_date_time,
                fe_started_date_time: val.fe_started_date_time,
                fe_current_location: {
                    latitude: val.fe_base_coordinates.latitude,
                    longitude: val.fe_base_coordinates.longitude,
                },
                visit_type: 0,
                user_id: val.user_id,
                visites: [],
                fe_name: val.fe_name,
                center_name: val.center_name,
                base_location_address: val.base_location_address,
            };
            for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
                var res = _a[_i];
                if (val.user_id == res.user_id) {
                    obj.visites = __spreadArray(__spreadArray([], obj.visites, true), [
                        {
                            latitude: res.center_coordinates.latitude,
                            longitude: res.center_coordinates.longitude,
                            scheduled_time: res.scheduled_time,
                            visit_type: res.visit_type,
                            center_name: res.center_name,
                            fe_code: res.fe_user_id,
                            center_info: res.center_info,
                        },
                    ], false);
                }
            }
            return obj;
        }
        catch (error) {
            console.log(error);
        }
    });
    return data;
};
exports.filterUniqueFEData = filterUniqueFEData;
var filterUnassiedVisitsData = function (response) {
    var data = [];
    data = response.map(function (val) {
        try {
            var obj = {
                latitude: val.center_coordinates.latitude,
                longitude: val.center_coordinates.longitude,
                scheduled_time: val.scheduled_time,
                visit_type: val.visit_type,
                center_name: val.center_name,
                center_info: val.center_info,
            };
            return obj;
        }
        catch (error) {
            console.log(error);
        }
    });
    return data;
};
exports.filterUnassiedVisitsData = filterUnassiedVisitsData;
var preciousSampleKey = function (params) {
    var value = '--';
    if (params === true) {
        return 'True';
    }
    else if (params === false) {
        return 'False';
    }
    else {
        return value;
    }
};
exports.preciousSampleKey = preciousSampleKey;
var ondemandKey = function (params) {
    var value = '--';
    if (params.value == Object.keys(auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS)[1]) {
        return auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS[1].toLocaleUpperCase();
    }
    else if (params.value == Object.keys(auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS)[6]) {
        return auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS[6].toLocaleUpperCase();
    }
    else if (params.value == Object.keys(auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS)[4]) {
        return auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS[4].toLocaleUpperCase();
    }
    else if (params.value == Object.keys(auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS)[0]) {
        return auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS[0].toLocaleUpperCase();
    }
    else if (params.value == Object.keys(auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS)[8]) {
        return auto_routing_helper_1.AUTO_ROUTING_VISIT_STATUS[8].toLocaleUpperCase();
    }
    else if (params.value == null) {
        return 'UN ASSIGNED';
    }
    else {
        return value;
    }
};
exports.ondemandKey = ondemandKey;
var trueFalseCoverter = function (params) {
    console.log(params, 'kjhg,mnbvcfd');
    var value = '--';
    if (params === 1) {
        return 'True';
    }
    else if (params === 0) {
        return 'False';
    }
    else {
        return value;
    }
};
exports.trueFalseCoverter = trueFalseCoverter;
var getAssignmentStatusFromId = function (id, assignmentTypeList) {
    var assignmentType = '';
    assignmentTypeList.map(function (singleassignmentType) {
        if (singleassignmentType.id == id) {
            assignmentType = singleassignmentType.label;
        }
    });
    return assignmentType;
};
exports.getAssignmentStatusFromId = getAssignmentStatusFromId;
var convertVisitsFromRes = function (response) {
    var visitStatusList = store_1.store.getState().appConfigList.visitStatusList;
    var visitTypesList = store_1.store.getState().appConfigList.visitTypesList;
    var routing_assignment_type = store_1.store.getState().appConfigList.routing_assignments;
    var resData = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var res = _a[_i];
        var feName = '';
        if (res.fe_first_name && res.fe_last_name) {
            feName = "".concat(res.fe_first_name, " ").concat(res.fe_last_name);
        }
        else if (res.fe_first_name) {
            feName = "".concat(res.fe_first_name);
        }
        var obj = {
            fe_id: res.fe_id,
            fe_name: feName,
            center_name: res.center_name ? res.center_name : '',
            warehouse_code: res.warehouse_code ? res.warehouse_code : '',
            visit_date: res.visit_date ? res.visit_date : null,
            scheduled_start_time: res.scheduled_start_time
                ? res.scheduled_start_time
                : null,
            mobile: res.mobile ? res.mobile : '',
            labCode: res.lab_code ? res.lab_code : '',
            distance: res.distance ? res.distance.toFixed(2) : null,
            visitStatus: res.visit_status >= 0
                ? (0, exports.getVisitStatusFromIDs)(res.visit_status, visitStatusList)
                : '',
            visitType: res.visit_type
                ? (0, exports.getVisitTypeFromIDs)(res.visit_type, visitTypesList)
                : '',
            nothing_to_pickup: res.nothing_to_pickup
                ? (0, exports.trueFalseCoverter)(res.nothing_to_pickup)
                : '',
            actual_pickup_time: res.actual_pickup_time ? res.actual_pickup_time : '',
            assignment_type: res.assignment_type
                ? (0, exports.getAssignmentStatusFromId)(res.assignment_type, routing_assignment_type)
                : '',
            actual_hub_handover_time: res.actual_hub_handover_time
                ? res.actual_hub_handover_time
                : '',
            actual_lab_handover_time: res.actual_lab_handover_time
                ? res.actual_lab_handover_time
                : '',
        };
        resData.push(obj);
    }
    return resData;
};
exports.convertVisitsFromRes = convertVisitsFromRes;
var convertDownloadReportVisitsFromRes = function (response) {
    var visitStatusList = store_1.store.getState().appConfigList.visitStatusList;
    var visitTypesList = store_1.store.getState().appConfigList.visitTypesList;
    var resData = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var res = _a[_i];
        var feName = '';
        if (res.fe_first_name && res.fe_last_name) {
            feName = "".concat(res.fe_first_name, " ").concat(res.fe_last_name);
        }
        else if (res.fe_first_name) {
            feName = "".concat(res.fe_first_name);
        }
        var obj = {
            fe_id: res.fe_id ? res.fe_id : '',
            fe_name: feName ? feName : '',
            mobile: res.mobile ? res.mobile : '',
            center_name: res.center_name ? res.center_name : '',
            warehouse_code: res.warehouse_code ? res.warehouse_code : '',
            visit_date: res.visit_date ? (0, exports.visitDateFormatter)(res.visit_date) : '',
            scheduled_start_time: res.scheduled_start_time
                ? res.scheduled_start_time
                : '',
            visit_id: res.visit_id ? res.visit_id : '',
            visitStatus: res.visit_status >= 0
                ? (0, exports.getVisitStatusFromIDs)(res.visit_status, visitStatusList)
                : '',
            visitType: res.visit_type
                ? (0, exports.getVisitTypeFromIDs)(res.visit_type, visitTypesList)
                : '',
            fe_reached_date_time: res.fe_reached_date_time
                ? (0, exports.visitsListDateTimeFormatter)(res.fe_reached_date_time)
                : '',
            fe_started_date_time: res.fe_started_date_time
                ? (0, exports.visitsListDateTimeFormatter)(res.fe_started_date_time)
                : '',
            lab_code: res.lab_code ? res.lab_code : '',
            distance: res.distance ? res.distance.toFixed(2) : '',
            nothing_to_pickup: res.nothing_to_pickup ? res.nothing_to_pickup : '',
            actual_pickup_time: res.actual_pickup_time ? res.actual_pickup_time : '',
            actual_hub_handover_time: res.actual_hub_handover_time
                ? res.actual_hub_handover_time
                : '',
            actual_lab_handover_time: res.actual_lab_handover_time
                ? res.actual_lab_handover_time
                : '',
        };
        resData.push(obj);
    }
    return resData;
};
exports.convertDownloadReportVisitsFromRes = convertDownloadReportVisitsFromRes;
var getVisitStatusFromID = function (id, visitStatusList) { return __awaiter(void 0, void 0, void 0, function () {
    var visitStatus;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                visitStatus = '';
                return [4, visitStatusList.map(function (singlevisitStatus) {
                        if (singlevisitStatus.id === id) {
                            visitStatus = singlevisitStatus.label;
                        }
                    })];
            case 1:
                _a.sent();
                return [2, visitStatus];
        }
    });
}); };
exports.getVisitStatusFromID = getVisitStatusFromID;
var getVisitTypeFromID = function (id, visitTypeList) { return __awaiter(void 0, void 0, void 0, function () {
    var visitType;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                visitType = '';
                return [4, visitTypeList.map(function (singleVisitType) {
                        if (singleVisitType.id === id) {
                            visitType = singleVisitType.label;
                        }
                    })];
            case 1:
                _a.sent();
                return [2, visitType];
        }
    });
}); };
exports.getVisitTypeFromID = getVisitTypeFromID;
var flattenObj = function (ob) {
    var result = {};
    var obj = {};
    var name = '';
    for (var i in ob) {
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            var temp = (0, exports.flattenObj)(ob[i]);
            for (var j in temp) {
                result[j] = temp[j];
            }
        }
        else {
            result[i] = ob[i];
        }
    }
    for (var i in result) {
        if (i === 'first_name' || i === 'last_name') {
            name = name + ' ' + result[i];
            obj['name'] = name;
        }
        else if (i === 'fe_id') {
            delete obj['fe_id'];
        }
        else if (i === 'center_visit') {
            delete obj['center_visit'];
        }
        else if (i === 'is_center_visit_started') {
            delete obj['is_center_visit_started'];
        }
        else if (i === 'visit_cnt') {
            delete obj['visit_cnt'];
        }
        else if (result[i] === '') {
            obj[i] = '--';
        }
        else if (i === 'total_distance_travelled') {
            obj['total_distance_travelled'] = parseFloat(result[i].toFixed(2));
        }
        else if (typeof result[i] === 'number') {
            obj[i] = parseInt(result[i]);
        }
        else if (i === 'unique_clients') {
            obj['unique_clients'] = result[i].length;
        }
        else {
            obj[i] = result[i];
        }
    }
    return obj;
};
exports.flattenObj = flattenObj;
var convertFE_Types_From_Config = function (response) {
    var feTypesLabelAndValue = [];
    if (response != null && response != undefined) {
        response.forEach(function (obj, i) {
            feTypesLabelAndValue.push({
                label: obj.type,
                value: obj.id,
                id: obj.id,
                remarks: obj.remark,
            });
        });
    }
    return feTypesLabelAndValue;
};
exports.convertFE_Types_From_Config = convertFE_Types_From_Config;
var getFeaturesList = function (uniqueMenuList, features) {
    var featuresList = [];
    uniqueMenuList.map(function (feature) {
        var module = {};
        var moduleFeatures = [];
        module['moduleName'] = feature.moduleName;
        features.map(function (subFeature) {
            if (feature.moduleName === subFeature.moduleName) {
                moduleFeatures.push(subFeature);
            }
        });
        module['features'] = moduleFeatures;
        featuresList.push(module);
    });
    return featuresList;
};
exports.getFeaturesList = getFeaturesList;
var convertActiveFEListFromRes = function (res) {
    var convertedRes = [];
    res === null || res === void 0 ? void 0 : res.result.map(function (feResObj) {
        var feObj = {
            id: feResObj.id,
            vendor_name: feResObj.vendor_name ? feResObj.vendor_name : '',
            is_replaced_fe: (0, exports.ReplacetrueFalseCoverter)(feResObj.is_replaced_fe),
            replacement_fe_name: feResObj.replacement_fe_name
                ? feResObj.replacement_fe_name
                : '',
            replacement_fe_id: feResObj.replacement_fe_id
                ? feResObj.replacement_fe_id
                : '',
            first_name: feResObj.first_name ? feResObj.first_name : '',
            last_name: feResObj.last_name ? feResObj.last_name : '',
            fe_id: feResObj.fe_id ? feResObj.fe_id : '',
            mobile: feResObj.mobile ? feResObj.mobile : '',
            state: feResObj.state ? feResObj.state : '',
            city: feResObj.city ? feResObj.city : '',
            base_latitude: feResObj.base_latitude ? feResObj.base_latitude : '',
            base_longitude: feResObj.base_longitude ? feResObj.base_longitude : '',
            lab: feResObj.lab && feResObj.lab.lab_code
                ? {
                    lab_code: feResObj.lab.lab_code || '',
                    center_city: feResObj.lab.center_city || '',
                }
                : {
                    lab_code: '',
                    center_city: '',
                },
            base_location_address: feResObj.base_location_address
                ? feResObj.base_location_address
                : '',
            exit_reason: feResObj.exit_reason ? feResObj.exit_reason : '',
            date_of_exit: feResObj.date_of_exit ? feResObj.date_of_exit : '',
            updated_date_time: feResObj.updated_date_time
                ? feResObj.updated_date_time
                : '',
            role_id: feResObj.role_id ? feResObj.role_id : '',
            image: feResObj.avatar ? feResObj.avatar : '',
            regional_manager1: feResObj.regional_manager1 ? feResObj.regional_manager1 : '',
            reason_type: feResObj.reason_type ? feResObj.reason_type : '',
            working_state: feResObj.working_state ? feResObj.working_state : '',
            working_city: feResObj.working_city ? feResObj.working_city : '',
            geofence_enabled: feResObj.geofence_enabled ? feResObj.geofence_enabled : '',
            geofence_disable_till: feResObj.geofence_disable_till ? feResObj.geofence_disable_till : '',
            geofence_lab_config: feResObj.lab ? feResObj.lab : '',
        };
        convertedRes.push(feObj);
    });
    var finalConvertedObj = {
        page: res.page,
        count: res.count,
        size: res.size,
        result: convertedRes,
    };
    return finalConvertedObj;
};
exports.convertActiveFEListFromRes = convertActiveFEListFromRes;
var convertLeavesListFromRes = function (res) {
    var convertedRes = [];
    res.result.map(function (leaveResObj) {
        var feObj = {
            id: leaveResObj.user_id ? leaveResObj.user_id : '',
            leave_id: leaveResObj.leave_id ? leaveResObj.leave_id : '',
            fe_id: leaveResObj.fe_id ? leaveResObj.fe_id : '',
            first_name: leaveResObj.first_name ? leaveResObj.first_name : '',
            last_name: leaveResObj.last_name ? leaveResObj.last_name : '',
            mobile: leaveResObj.mobile ? leaveResObj.mobile : '',
            city: leaveResObj.city ? leaveResObj.city : '',
            state: leaveResObj.state ? leaveResObj.state : '',
            from_date: leaveResObj.from_date ? leaveResObj.from_date : '',
            to_date: leaveResObj.to_date ? leaveResObj.to_date : '',
            applied_date: leaveResObj.applied_date ? leaveResObj.applied_date : '',
            total_days: leaveResObj.total_days ? leaveResObj.total_days : 0,
            reason: leaveResObj.reason ? leaveResObj.reason : '',
            comment: leaveResObj.comment ? leaveResObj.comment : '',
            status: leaveResObj.status ? leaveResObj.status : '',
        };
        convertedRes.push(feObj);
    });
    var finalConvertedObj = {
        page: res.page,
        count: res.count,
        size: res.size,
        result: convertedRes,
    };
    return finalConvertedObj;
};
exports.convertLeavesListFromRes = convertLeavesListFromRes;
var convertRejectedFEListFromRes = function (res) {
    var convertedRes = [];
    res.result.map(function (feResObj) {
        var feObj = {
            vendor_name: feResObj.vendor_name ? feResObj.vendor_name : '',
            first_name: feResObj.first_name ? feResObj.first_name : '',
            last_name: feResObj.last_name ? feResObj.last_name : '',
            is_replaced_fe: (0, exports.ReplacetrueFalseCoverter)(feResObj.is_replaced_fe),
            replacement_fe_name: feResObj.replacement_fe_name
                ? feResObj.replacement_fe_name
                : '',
            replacement_fe_id: feResObj.replacement_fe_id
                ? feResObj.replacement_fe_id
                : '',
            mobile: feResObj.mobile ? feResObj.mobile : '',
            reject_reason: feResObj.reject_reason ? feResObj.reject_reason : '',
            reject_reason_id: feResObj.reject_reason ? feResObj.reject_reason : '',
            reject_comments: feResObj.reject_comments ? feResObj.reject_comments : '',
            rejected_date: feResObj.rejected_date ? feResObj.rejected_date : '',
            state: feResObj.state ? feResObj.state : '',
            city: feResObj.city ? feResObj.city : '',
            lab: feResObj.lab && feResObj.lab.lab_code && feResObj.lab.center_city
                ? feResObj.lab
                : {
                    lab_code: '',
                    center_city: '',
                },
            base_location_address: feResObj.base_location_address
                ? feResObj.base_location_address
                : '',
            id: feResObj.id ? feResObj.id : '',
            updated_date_time: feResObj.updated_date_time
                ? feResObj.updated_date_time
                : '',
            working_state: feResObj.working_state ? feResObj.working_state : '',
            working_city: feResObj.working_city ? feResObj.working_city : '',
        };
        convertedRes.push(feObj);
    });
    var finalConvertedObj = {
        page: res.page,
        count: res.count,
        size: res.size,
        result: convertedRes,
    };
    return finalConvertedObj;
};
exports.convertRejectedFEListFromRes = convertRejectedFEListFromRes;
var isFieldValueTrueFalse = function (rowObject) {
    return parseInt(rowObject === null || rowObject === void 0 ? void 0 : rowObject.value, 10) >= 0
        ? Boolean(rowObject.value).toString()
        : '--';
};
exports.isFieldValueTrueFalse = isFieldValueTrueFalse;
var convertIntoNumber = function (fieldValue) {
    var value = '--';
    return fieldValue ? (Math.round(fieldValue) * 10) / 10 : value;
};
exports.convertIntoNumber = convertIntoNumber;
var moduleFeatures = function (moduleName) {
    var featuresList = store_1.store.getState().appConfigList.featureslist;
    var features = featuresList === null || featuresList === void 0 ? void 0 : featuresList.filter(function (feature) {
        if (feature.moduleName === moduleName) {
            return feature;
        }
    });
    return features;
};
exports.moduleFeatures = moduleFeatures;
var disableTabs = function (tabs, moduleFeatures) {
    var arr = [];
    tabs.map(function (tab) {
        if (moduleFeatures[tab.fieldName]) {
            tab['islinkDisabled'] = false;
        }
        else {
            console.log('disable');
            tab['islinkDisabled'] = true;
        }
        arr.push(tab);
    });
    return arr;
};
exports.disableTabs = disableTabs;
var convertVendorsListFromres = function (res) {
    var convertedRes = [];
    res.map(function (vendorObj) {
        var operatingPlaces = [];
        vendorObj.operating_places.map(function (operatingPlace) {
            var singleOperatingPlace = {
                city_id: operatingPlace.city_id,
                state_id: operatingPlace.state_id,
                state_name: operatingPlace.state_name ? operatingPlace.state_name : '',
                city_name: operatingPlace.city_name ? operatingPlace.city_name : '',
                active: operatingPlace.active,
            };
            operatingPlaces.push(singleOperatingPlace);
        });
        var convertedresSingleObj = {
            id: vendorObj.id,
            name: vendorObj.name ? vendorObj.name : '',
            onboard_status: vendorObj.onboard_status ? vendorObj.onboard_status : '',
            axapta_code: vendorObj.axapta_code ? vendorObj.axapta_code : '',
            contact_name: vendorObj.contact_name ? vendorObj.contact_name : '',
            contact_mobile: vendorObj.contact_mobile ? vendorObj.contact_mobile : '',
            contact_email: vendorObj.contact_email ? vendorObj.contact_email : '',
            active_fes: vendorObj === null || vendorObj === void 0 ? void 0 : vendorObj.active_fes.toString(),
            operating_places: operatingPlaces,
        };
        convertedRes.push(convertedresSingleObj);
    });
    return convertedRes;
};
exports.convertVendorsListFromres = convertVendorsListFromres;
var convertRoleListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (roleObj) {
        var convertedResObj = {
            id: roleObj.id ? roleObj.id : '',
            app_id: roleObj.app_id ? roleObj.app_id : null,
            name: roleObj.name ? roleObj.name : '',
            description: roleObj.description ? roleObj.description : '',
            created_date_time: roleObj.created_date_time
                ? roleObj.created_date_time
                : null,
            modified_on: roleObj.modified_on ? roleObj.modified_on : null,
            modified_by: roleObj.modified_by ? roleObj.modified_by : '',
            status: roleObj.status ? roleObj.status : '',
        };
        convertedRes.push(convertedResObj);
    });
    return convertedRes;
};
exports.convertRoleListFromRes = convertRoleListFromRes;
var getAccessibleFeatures = function (moduleName) {
    var featuresList = (0, exports.moduleFeatures)(moduleName);
    var features = {};
    if (featuresList && featuresList.length > 0) {
        featuresList[0].features.map(function (feature) {
            var key = feature.featureName.replace(/ /g, '_');
            features[key] = feature.isMenuItem;
        });
    }
    return features;
};
exports.getAccessibleFeatures = getAccessibleFeatures;
var featureCheck = function (data, featureName) {
    var isFeature = false;
    data[0].features.filter(function (feature) {
        if (feature.featureName === featureName) {
            isFeature = feature.isMenuItem;
        }
    });
    return isFeature;
};
exports.featureCheck = featureCheck;
var filterFE_IdsForHeatMap = function (response) {
    var uniqueFes = lodash_1.default.uniqBy(response, 'user_id');
    var FE_Ids = [];
    uniqueFes.map(function (feInfo) {
        FE_Ids.push({
            id: feInfo.user_id,
            label: feInfo.fe_user_id,
            value: feInfo.fe_user_id,
        });
    });
    return FE_Ids;
};
exports.filterFE_IdsForHeatMap = filterFE_IdsForHeatMap;
var filterSelectedVisitList = function (response, selectedFEId) {
    console.log(response, 'cheresponse', selectedFEId);
    var filterFEIDs = [];
    selectedFEId.map(function (res) {
        filterFEIDs.push(res.label);
    });
    var finalVistsList = response.filter(function (res) {
        return filterFEIDs && res && filterFEIDs.includes(res.fe_code);
    });
    return finalVistsList;
};
exports.filterSelectedVisitList = filterSelectedVisitList;
var isValueEmpty = function (data) {
    var value = '--';
    return data ? data : value;
};
exports.isValueEmpty = isValueEmpty;
var convertWareHouseResponse = function (response) {
    var warehouseCodes = [];
    for (var _i = 0, response_5 = response; _i < response_5.length; _i++) {
        var warehouse = response_5[_i];
        warehouseCodes.push({
            label: "".concat(warehouse.warehouse_code, ",").concat(warehouse.centre_name),
            value: warehouse.warehouse_code,
            state: warehouse.centre_city,
            city: warehouse.centre_state,
            warehouseCode: warehouse.warehouse_code,
            centre_name: warehouse.centre_name,
        });
    }
    return warehouseCodes;
};
exports.convertWareHouseResponse = convertWareHouseResponse;
var convertUsersListFromRes = function (res) {
    var convertedresArr = [];
    res.forEach(function (user) { return __awaiter(void 0, void 0, void 0, function () {
        var roleFromres, locationFromRes, convertedres;
        return __generator(this, function (_a) {
            roleFromres = '';
            locationFromRes = '';
            if (user.roles) {
                user.roles.forEach(function (role) {
                    if (roleFromres.length == 0) {
                        roleFromres += role;
                    }
                    else {
                        roleFromres += ',' + role;
                    }
                });
            }
            if (user.cities) {
                user.cities.forEach(function (city) {
                    if (locationFromRes.length == 0) {
                        locationFromRes += city;
                    }
                    else {
                        locationFromRes += ',' + city;
                    }
                });
            }
            convertedres = {
                fullName: user.full_name ? user.full_name : '',
                role: roleFromres,
                userName: user.user_name ? user.user_name : '',
                location: locationFromRes,
                email: user.email ? user.email : '',
                mobile: user.phone_number ? user.phone_number : '',
                branch: user.branch ? user.branch : '',
                id: user.id ? user.id : '',
                employee_code: user.employee_code ? user.employee_code : '',
                name: 'Selectlab',
            };
            convertedresArr.push(convertedres);
            return [2];
        });
    }); });
    return convertedresArr;
};
exports.convertUsersListFromRes = convertUsersListFromRes;
var convertUserRoleListFromRes = function (res) {
    var convertedResArr = [];
    res.forEach(function (role) {
        var convertedRes = {
            id: role.id,
            label: role.name,
            value: role.name,
        };
        convertedResArr.push(convertedRes);
    });
    return convertedResArr;
};
exports.convertUserRoleListFromRes = convertUserRoleListFromRes;
var convertRouteAssignmentTypeFromRes = function (response) {
    var routeAssignmentType = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            routeAssignmentType.push({
                label: response[each].toString().toUpperCase(),
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return routeAssignmentType;
};
exports.convertRouteAssignmentTypeFromRes = convertRouteAssignmentTypeFromRes;
var convertReportStatusDropdownOptionFromConfig = function (response) {
    var reportStatusLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            reportStatusLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return reportStatusLabelAndValue;
};
exports.convertReportStatusDropdownOptionFromConfig = convertReportStatusDropdownOptionFromConfig;
var convertReportTypeDropdownOptionFromConfig = function (response) {
    var reportTypeLabelAndValue = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            reportTypeLabelAndValue.push({
                label: response[each],
                value: parseInt(each),
                id: parseInt(each),
            });
        });
    }
    return reportTypeLabelAndValue;
};
exports.convertReportTypeDropdownOptionFromConfig = convertReportTypeDropdownOptionFromConfig;
var createPayloadForFEList = function (feType, searchValue, selectedCities) {
    var activeUserPayload = {
        onboard_status: [feType],
    };
    if (searchValue.length <= 3 &&
        searchValue.length === 0 &&
        selectedCities.length === 0) {
        activeUserPayload.onboard_status = [feType];
    }
    if (selectedCities.length >= 1) {
        activeUserPayload.city = selectedCities;
    }
    if (searchValue.length >= 3) {
        activeUserPayload.search_string = searchValue;
    }
    return activeUserPayload;
};
exports.createPayloadForFEList = createPayloadForFEList;
var createPayloadForOnLeaveToday = function (status, searchValue, selectedCities, vendor_id, date_range, geographies) {
    var payload = {
        vendor_id: vendor_id,
        status: [status],
        date_range: date_range,
    };
    if (searchValue.length <= 3 &&
        searchValue.length === 0 &&
        selectedCities.length === 0) {
        payload = {
            vendor_id: vendor_id,
            status: [status],
            date_range: date_range,
        };
    }
    if (selectedCities.length >= 1) {
        payload = {
            vendor_id: vendor_id,
            status: [status],
            date_range: date_range,
            geographies: geographies,
        };
    }
    else if (searchValue.length >= 3) {
        payload = {
            vendor_id: vendor_id,
            status: [status],
            date_range: date_range,
            search_key: searchValue,
        };
    }
    return payload;
};
exports.createPayloadForOnLeaveToday = createPayloadForOnLeaveToday;
var createPayloadFEForApprovalPending = function (feType, searchValue, selectedCities) {
    var activeUserPayload = {
        onboard_status: feType,
    };
    if (searchValue.length <= 3 &&
        searchValue.length === 0 &&
        selectedCities.length === 0) {
        activeUserPayload.onboard_status = feType;
    }
    if (selectedCities.length >= 1) {
        activeUserPayload.city = selectedCities;
    }
    else if (searchValue.length >= 3) {
        activeUserPayload.search_string = searchValue;
    }
    return activeUserPayload;
};
exports.createPayloadFEForApprovalPending = createPayloadFEForApprovalPending;
var createPayloadForVendorList = function (onboard_status, searchValue, selectedCities) {
    var activeUserPayload = {
        onboard_status: onboard_status,
        limit: 20,
    };
    if (searchValue.length <= 3 &&
        searchValue.length === 0 &&
        selectedCities.length === 0) {
        activeUserPayload.onboard_status = onboard_status;
    }
    if (selectedCities.length >= 1) {
        activeUserPayload.city_ids = selectedCities;
    }
    if (searchValue.length >= 3) {
        activeUserPayload.search_string = searchValue;
    }
    return activeUserPayload;
};
exports.createPayloadForVendorList = createPayloadForVendorList;
var convertCREListFromRes = function (res) {
    var convertedRes = [];
    res === null || res === void 0 ? void 0 : res.map(function (creResObj) {
        var creName = (0, cre_api_calls_1.ConcateCreFnameLname)(creResObj.first_name, creResObj.last_name);
        var feObj = {
            cre_name: creName ? creName : '',
            mobile: creResObj.mobile ? creResObj.mobile : '',
            lab_code: creResObj.lab_code ? creResObj.lab_code : '',
            ax_code: creResObj.ax_code ? creResObj.ax_code : '',
            email: creResObj.email ? creResObj.email : '',
            id: creResObj.id ? creResObj.id : '',
            isActive: creResObj.is_active ? creResObj.is_active : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertCREListFromRes = convertCREListFromRes;
var convertUserLogisticsListFromRes = function (res) {
    var convertedRes = [];
    res === null || res === void 0 ? void 0 : res.map(function (userResObj) {
        var username = (0, exports.concatFirstNameLastName)(userResObj.first_name, userResObj.last_name);
        var feObj = {
            user_name: username ? username : '',
            phone_number: userResObj.phone_number ? userResObj.phone_number : '',
            address: userResObj.address ? userResObj.address : '',
            tagged_region_name: userResObj.tagged_region_name ? userResObj.tagged_region_name : '',
            dob: userResObj.dob ? userResObj.dob : '',
            email: userResObj.email ? userResObj.email : '',
            id: userResObj.id ? userResObj.id : '',
            gender: userResObj.gender ? userResObj.gender : '',
            fullName: username ? username : '',
            userName: userResObj.first_name ? userResObj.first_name : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertUserLogisticsListFromRes = convertUserLogisticsListFromRes;
var convertUserDatails = function (res, entity_type) {
    var _a, _b;
    var data = entity_type.filter(function (entity_types) { return entity_types.id == res.entity_type; });
    var convertedRes = {};
    convertedRes.name = res.name ? res.name : '--';
    convertedRes.phone = res.phone ? res.phone : '--';
    convertedRes.email = res.email ? res.email : '--';
    convertedRes.code = res.code ? res.code : '--';
    convertedRes.emaployeeCode = res.employee_code ? res.employee_code : '--';
    convertedRes.city = res.city ? res.city : '--';
    convertedRes.onBoardStatus = res.onBoardStatus ? res.onBoardStatus : 1;
    convertedRes.state = res.state ? res.state : '--';
    convertedRes.entityType = ((_a = data[0]) === null || _a === void 0 ? void 0 : _a.label) ? (_b = data[0]) === null || _b === void 0 ? void 0 : _b.label : '--';
    return convertedRes;
};
exports.convertUserDatails = convertUserDatails;
var convertCCListResponse = function (response) {
    console.log(response, '5678');
    var convertedResponse = [];
    response.forEach(function (each, i) {
        convertedResponse.push({
            label: "".concat(each.warehouse_code ? each.warehouse_code : '').concat(each.invoice_code ? ", ".concat(each.invoice_code) : '').concat(each.center_name ? ", ".concat(each.center_name) : '').concat(each.center_state ? ", ".concat(each.center_state) : '').concat(each.center_city ? ", ".concat(each.center_city) : ''),
            value: each.warehouse_code,
            id: each.id,
            centre_state: each.center_state,
            center_city: each.center_city,
            invoice_code: each.invoice_code,
            name: each.center_name
        });
    });
    return convertedResponse;
};
exports.convertCCListResponse = convertCCListResponse;
var convertLabelAndValueCCList = function (response) {
    var ccTypesLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        ccTypesLabelAndValue.push({
            id: data.centre_name,
            center_state: data.centre_state,
            center_city: data.centre_city,
            value: data.centre_name,
            label: "".concat(data.centre_name || '', " ,  ").concat(data.warehouse_code || ''),
        });
    }
    return ccTypesLabelAndValue;
};
exports.convertLabelAndValueCCList = convertLabelAndValueCCList;
var concatenateLabCodeWithAddress = function (response) {
    var convertedResponse = [];
    response.forEach(function (each, i) {
        convertedResponse.push({
            label: "".concat(each.value, " , ").concat(each.center_name, ",").concat(each.center_city, ",").concat(each.center_state),
            value: each.value,
            id: each.id,
            centre_state: each.center_state,
            center_city: each.center_city,
            center_name: each.center_name,
        });
    });
    return convertedResponse;
};
exports.concatenateLabCodeWithAddress = concatenateLabCodeWithAddress;
var getAccessibleRoutes = function (moduleName, routeList) {
    var featuresList = (0, exports.moduleFeatures)(moduleName);
    var filterdRoutes = [];
    if (featuresList && featuresList[0] && featuresList[0].features) {
        featuresList[0].features.forEach(function (feature) {
            var filteredArray = routeList.filter(function (arr1Obj) { return arr1Obj.featureName === feature.featureName; });
            if (filteredArray.length > 0) {
                filterdRoutes = __spreadArray(__spreadArray([], filterdRoutes, true), routeList.filter(function (obj) { return obj.featureName === feature.featureName; }), true);
            }
        });
        return filterdRoutes;
    }
};
exports.getAccessibleRoutes = getAccessibleRoutes;
var getAccessibleRoutesForSSOReports = function (moduleName, routeList, is_sso_user) {
    var filterdRoutes = [];
    if (routeList.length > 0 && is_sso_user)
        filterdRoutes = __spreadArray(__spreadArray([], filterdRoutes, true), routeList.filter(function (obj) { return obj.featureName === moduleName; }), true);
    return filterdRoutes;
};
exports.getAccessibleRoutesForSSOReports = getAccessibleRoutesForSSOReports;
var filterFeatures = function (routesList, features) {
    var filterdRoutes = [];
    features === null || features === void 0 ? void 0 : features.forEach(function (feature) {
        var filteredArray = routesList.filter(function (arr1Obj) { return arr1Obj.moduleName === feature.moduleName; });
        if (filteredArray.length > 0) {
            filterdRoutes = __spreadArray(__spreadArray([], filterdRoutes, true), routesList.filter(function (obj) { return obj.moduleName === feature.moduleName; }), true);
        }
    });
    return filterdRoutes;
};
exports.filterFeatures = filterFeatures;
var dateFormateYMD = function (date) {
    return moment(date).format('YYYY-MM-DD');
};
exports.dateFormateYMD = dateFormateYMD;
var downloadExcel = function (data, filename, headers, sheetName) {
    var worksheet = XLSX.utils.json_to_sheet(data);
    var workbook = XLSX.utils.book_new();
    var Heading = [__spreadArray([], headers, true)];
    XLSX.utils.sheet_add_aoa(worksheet, Heading);
    XLSX.utils.sheet_add_json(worksheet, data, {
        origin: 'A2',
        skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, filename);
};
exports.downloadExcel = downloadExcel;
var convertDayIntoDayid = function (day) {
    if (day == 'MONDAY') {
        return 'day1';
    }
    if (day == 'TUESDAY') {
        return 'day2';
    }
    if (day == 'WEDNESDAY') {
        return 'day3';
    }
    if (day == 'THURSDAY') {
        return 'day4';
    }
    if (day == 'FRIDAY') {
        return 'day5';
    }
    if (day == 'SATURDAY') {
        return 'day6';
    }
    if (day == 'SUNDAY') {
        return 'day7';
    }
    if (day == 'HOLIDAY') {
        return 'day8';
    }
};
exports.convertDayIntoDayid = convertDayIntoDayid;
var convertDayIDIntoId = function (day) {
    if (day == 'day1') {
        return 1;
    }
    if (day == 'day2') {
        return 2;
    }
    if (day == 'day3') {
        return 3;
    }
    if (day == 'day4') {
        return 4;
    }
    if (day == 'day5') {
        return 5;
    }
    if (day == 'day6') {
        return 6;
    }
    if (day == 'day7') {
        return 7;
    }
    if (day == 'day8') {
        return 8;
    }
};
exports.convertDayIDIntoId = convertDayIDIntoId;
var convertLabcodeResponse = function (labcode) {
    var ccTypesLabelAndValue = [];
    for (var _i = 0, labcode_1 = labcode; _i < labcode_1.length; _i++) {
        var data = labcode_1[_i];
        ccTypesLabelAndValue.push({
            id: data.id,
            center_state: data.center_state,
            center_city: data.center_city,
            center_name: data.center_name,
            value: data.warehouse_code,
            lab_latittude: data.lab_latittude,
            lab_longittude: data.lab_longittude,
            label: "".concat(data.warehouse_code ? data.warehouse_code : '').concat(data.center_name ? ", ".concat(data.center_name) : ''),
        });
    }
    return ccTypesLabelAndValue;
};
exports.convertLabcodeResponse = convertLabcodeResponse;
var restictSpecialCharecters = function (event) {
    if (new RegExp(/^[0-9]+$/).test(event.key)) {
    }
    else {
        event.preventDefault();
    }
};
exports.restictSpecialCharecters = restictSpecialCharecters;
var convertRoleDataResponse = function (role) {
    console.log(role, 'ytredfgh');
    var roledata = [];
    for (var _i = 0, role_1 = role; _i < role_1.length; _i++) {
        var data = role_1[_i];
        roledata.push({
            id: data.app_id,
            center_state: data.centre_state,
            center_city: data.centre_city,
            value: data.id,
            label: "".concat(data.name || '', " ,  ").concat(data.description || ''),
        });
    }
    return roledata;
};
exports.convertRoleDataResponse = convertRoleDataResponse;
var convertDownloadableExcelDataFromRes = function (res) {
    var downloadExcelDataErr = [];
    for (var _i = 0, res_1 = res; _i < res_1.length; _i++) {
        var singleObj = res_1[_i];
        downloadExcelDataErr.push({
            route_id: singleObj.visit_id ? singleObj.visit_id : '',
            invoice_code: singleObj.center_info && singleObj.center_info.invoice_code
                ? singleObj.center_info.invoice_code
                : '',
            warehouse_code: singleObj.center_info && singleObj.center_info.warehouse_code
                ? singleObj.center_info.warehouse_code
                : '',
            center_name: singleObj.center_name ? singleObj.center_name : '',
            distance: singleObj.distance ? singleObj.distance.toFixed(2) : '',
            start_time: singleObj.scheduled_time ? singleObj.scheduled_time : '',
            status: singleObj.visit_status ? singleObj.visit_status : '',
            visitType: singleObj.visit_type ? singleObj.visit_type : '',
            visitDate: singleObj.visit_date ? singleObj.visit_date : '',
            time_window: singleObj.time_window_slot ? singleObj.time_window_slot : '',
            fe_mobile: singleObj.mobile ? singleObj.mobile : '',
            fe_name: singleObj.fe_name ? singleObj.fe_name : '',
            latittude: singleObj.center_coordinates && singleObj.center_coordinates.latitude
                ? singleObj.center_coordinates.latitude
                : '',
            longittude: singleObj.center_coordinates && singleObj.center_coordinates.longitude
                ? singleObj.center_coordinates.longitude
                : '',
            lab_code: singleObj.center_info && singleObj.center_info.lab_code
                ? singleObj.center_info.lab_code
                : '',
        });
    }
    return downloadExcelDataErr;
};
exports.convertDownloadableExcelDataFromRes = convertDownloadableExcelDataFromRes;
var convertIntoIdLabel = function (data) {
    var id = 1;
    return {
        id: id,
        label: data,
        value: data,
    };
};
exports.convertIntoIdLabel = convertIntoIdLabel;
var getCurrentday = function (currentday) {
    switch (currentday) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        case 6:
            return 6;
        case 0:
            return 7;
        default:
            return '';
    }
};
exports.getCurrentday = getCurrentday;
var convertLabDataResponse = function (role) {
    console.log(role, 'ytredfgh');
    var labdata = [];
    for (var _i = 0, role_2 = role; _i < role_2.length; _i++) {
        var data = role_2[_i];
        labdata.push({
            id: data.id,
            center_state: data.lab_state,
            center_city: data.lab_city,
            value: data.warehouse_code,
            label: "".concat(data.lab_code || '', " ,  ").concat(data.lab_name || ''),
        });
    }
    return labdata;
};
exports.convertLabDataResponse = convertLabDataResponse;
var AxStatusKey = function (params) {
    var value = '--';
    if (params.value === 0) {
        return 'ACTIVE';
    }
    else if (params.value === 1) {
        return 'BLOCKED';
    }
    else {
        return value;
    }
};
exports.AxStatusKey = AxStatusKey;
var static_route_plan_assignment_types = function (assignment, route, speed, fe_radius) {
    console.log(speed, fe_radius, 'oooooooooooioi');
    switch (assignment) {
        case 1:
            return (route['staticspeed'] = speed);
        case 2:
            return (route['dynamicspeed'] = speed);
        default:
            return '';
    }
};
exports.static_route_plan_assignment_types = static_route_plan_assignment_types;
var static_route_plan_center_types = function (center, route, buffer_time) {
    switch (center) {
        case 1:
            return (route['static_cc'] = buffer_time);
        case 2:
            return (route['static_psc'] = buffer_time);
        case 3:
            return (route['static_fpsc'] = buffer_time);
        case 4:
            return (route['static_pup'] = buffer_time);
        case 5:
            return (route['static_lab'] = buffer_time);
        case 6:
            return (route['static_hub'] = buffer_time);
        default:
            return '';
    }
};
exports.static_route_plan_center_types = static_route_plan_center_types;
var dynamic_route_plan_center_types = function (center, route, buffer_time) {
    switch (center) {
        case 1:
            return (route['dynamic_cc'] = buffer_time);
        case 2:
            return (route['dynamic_psc'] = buffer_time);
        case 3:
            return (route['dynamic_fpsc'] = buffer_time);
        case 4:
            return (route['dynamic_pup'] = buffer_time);
        case 5:
            return (route['dynamic_lab'] = buffer_time);
        case 6:
            return (route['dynamic_hub'] = buffer_time);
        default:
            return '';
    }
};
exports.dynamic_route_plan_center_types = dynamic_route_plan_center_types;
var ondemandvisittype = function (params) {
    var value = '--';
    if (params.value === 1) {
        return 'PICKUP';
    }
    else if (params.value === 2) {
        return 'HUB_RECEIVING';
    }
    else if (params.value === 3) {
        return 'HANDOVER_HUB';
    }
    else if (params.value === 4) {
        return 'HANDOVER_LAB';
    }
    else if (params.value === 5) {
        return 'CASH_HANDOVER';
    }
    else if (params.value === 6) {
        return 'MASTER_BOX_PICKUP';
    }
    else if (params.value === 7) {
        return 'INTERCITY_HANDOVER';
    }
    else if (params.value === 8) {
        return 'INTERCITY_PICKUP';
    }
    else if (params.value === 9) {
        return 'HUB_RETURN';
    }
    else {
        return value;
    }
};
exports.ondemandvisittype = ondemandvisittype;
var capitalize = function (str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return word.toUpperCase();
    })
        .replace(/\s+/g, ' ');
};
exports.capitalize = capitalize;
var convertVisitStatus = function (params) {
    var _a;
    var visitStatusId = params.data.visit_status;
    var visitStatusType = store_1.store.getState().appConfigList.visitStatusList;
    console.log(visitStatusType, 'jiuygf');
    var value = '--';
    var visit = visitStatusType.filter(function (visitType) {
        if (visitType.id === visitStatusId) {
            return visitType;
        }
    });
    var visitStatus = (_a = visit[0]) === null || _a === void 0 ? void 0 : _a.label;
    console.log(visitStatus);
    if (visitStatus) {
        return visitStatus;
    }
    else {
        return value;
    }
};
exports.convertVisitStatus = convertVisitStatus;
var covertMasterBoxStatusFromConfig = function (response) {
    var convertedMasrterBoxRes = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            convertedMasrterBoxRes.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return convertedMasrterBoxRes;
};
exports.covertMasterBoxStatusFromConfig = covertMasterBoxStatusFromConfig;
var getVisitStatusFromIDs = function (id, visitStatusList) {
    var visitStatus = '';
    visitStatusList.map(function (singlevisitStatus) {
        if (singlevisitStatus.id === id) {
            visitStatus = singlevisitStatus.label;
        }
    });
    return visitStatus;
};
exports.getVisitStatusFromIDs = getVisitStatusFromIDs;
var getVisitTypeFromIDs = function (id, visitTypeList) {
    var visitType = '';
    visitTypeList.map(function (singleVisitType) {
        if (singleVisitType.id === id) {
            visitType = singleVisitType.label;
        }
    });
    return visitType;
};
exports.getVisitTypeFromIDs = getVisitTypeFromIDs;
var convertETRFStatus = function (params) {
    var _a;
    var ETRFStatusId = params.data ? params.data.status : params;
    var ETRFStatusType = store_1.store.getState().appConfigList.etrf_status;
    var value = '--';
    var ETRFStatus = ETRFStatusType === null || ETRFStatusType === void 0 ? void 0 : ETRFStatusType.filter(function (ETRFType) {
        if (ETRFType.id === ETRFStatusId) {
            return ETRFType;
        }
    });
    var ETRFstatus = (_a = ETRFStatus[0]) === null || _a === void 0 ? void 0 : _a.label;
    if (ETRFstatus) {
        return ETRFstatus;
    }
    else {
        return value;
    }
};
exports.convertETRFStatus = convertETRFStatus;
var convertLeaveListFromRes = function (res) {
    var convertedRes = [];
    res.result.map(function (leaveResObj) {
        var feObj = {
            id: leaveResObj.user_id ? leaveResObj.user_id : '',
            leave_id: leaveResObj.leave_id ? leaveResObj.leave_id : '',
            fe_id: leaveResObj.fe_id ? leaveResObj.fe_id : '',
            first_name: leaveResObj.first_name ? leaveResObj.first_name : '',
            last_name: leaveResObj.last_name ? leaveResObj.last_name : '',
            mobile: leaveResObj.mobile ? leaveResObj.mobile : '',
            city: leaveResObj.city ? leaveResObj.city : '',
            state: leaveResObj.state ? leaveResObj.state : '',
            from_date: leaveResObj.from_date ? leaveResObj.from_date : '',
            to_date: leaveResObj.to_date ? leaveResObj.to_date : '',
            applied_date: leaveResObj.applied_date ? leaveResObj.applied_date : '',
            total_days: leaveResObj.total_days ? leaveResObj.total_days : '',
            reason: leaveResObj.reason ? leaveResObj.reason : '',
            comment: leaveResObj.comment ? leaveResObj.comment : '',
            status: leaveResObj.status ? leaveResObj.status : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertLeaveListFromRes = convertLeaveListFromRes;
var convertAllVisitsListFromRes = function (res) {
    var _a;
    var visitStatusList = store_1.store.getState().appConfigList.visitStatusList;
    var convertedRes = [];
    (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (visitsResObj) {
        var feObj = {
            visit_date: visitsResObj.visit_date ? visitsResObj.visit_date : '',
            fe_id: visitsResObj.fe_id ? visitsResObj.fe_id : '',
            first_name: visitsResObj.first_name ? visitsResObj.first_name : '',
            last_name: visitsResObj.last_name ? visitsResObj.last_name : '',
            mobile: visitsResObj.mobile ? visitsResObj.mobile : '',
            fe_city: visitsResObj.fe_city ? visitsResObj.fe_city : '',
            fe_hub: visitsResObj.fe_hub ? visitsResObj.fe_hub : '',
            warehouse_code: visitsResObj.warehouse_code
                ? visitsResObj.warehouse_code
                : '',
            center_name: visitsResObj.center_name ? visitsResObj.center_name : '',
            center_city: visitsResObj.center_city ? visitsResObj.center_city : '',
            center_hub_code: visitsResObj.center_hub_code
                ? visitsResObj.center_hub_code
                : '',
            center_hub_name: visitsResObj.center_hub_name
                ? visitsResObj.center_hub_name
                : '',
            lab_code: visitsResObj.lab_code ? visitsResObj.lab_code : '',
            visit_status: visitsResObj.visit_status >= 0
                ? (0, exports.getVisitStatusFromIDs)(visitsResObj.visit_status, visitStatusList)
                : '',
            center_state: visitsResObj.center_state ? visitsResObj.center_state : '',
            fe_state: visitsResObj.fe_state ? visitsResObj.fe_state : '',
            nothing_to_pickup: visitsResObj.nothing_to_pickup
                ? visitsResObj.nothing_to_pickup
                : '',
            time_window_slot: visitsResObj.time_window_slot
                ? visitsResObj.time_window_slot
                : '',
            total_assigned_visits: visitsResObj.total_assigned_visits
                ? visitsResObj.total_assigned_visits
                : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertAllVisitsListFromRes = convertAllVisitsListFromRes;
var concatName = function (first_name, last_name) {
    var feFullName = '';
    first_name && last_name
        ? (feFullName = "".concat(first_name && first_name, " ").concat(last_name && last_name))
        : (feFullName = FECreationTabConstant_1.emptyDetails);
    return feFullName;
};
exports.concatName = concatName;
var selecteAllOption = function (selectedList, labList) {
    var payload;
    [];
    var selectedData = [];
    selectedList.map(function (selectedCityObj) {
        selectedData.push(selectedCityObj.value);
    });
    payload = selectedList.length == labList.length ? ['ALL'] : selectedData;
    return payload;
};
exports.selecteAllOption = selecteAllOption;
var convertNotificationCategoriesFromRes = function (response) {
    var notificationCategory = [];
    if (response != null && response != undefined) {
        var keys = Object.keys(response);
        keys.forEach(function (each, i) {
            notificationCategory.push({
                label: response[each],
                value: response[each],
                id: parseInt(each),
            });
        });
    }
    return notificationCategory;
};
exports.convertNotificationCategoriesFromRes = convertNotificationCategoriesFromRes;
var NotificationCat = function (cat) {
    console.log(cat, 'oiuyt');
    switch (cat) {
        case 1:
            return 'None';
        case 2:
            return 'Emergency';
        case 3:
            return 'General';
        case 4:
            return 'Important';
        default:
            return '';
    }
};
exports.NotificationCat = NotificationCat;
var NotificationFreq = function (freq) {
    switch (freq) {
        case 1:
            return 'Every_monday';
        case 2:
            return 'Every_tuesday';
        case 3:
            return 'Every_wednesday';
        case 4:
            return 'Every_thursday';
        case 5:
            return 'Every_friday';
        case 6:
            return 'Every_saturday';
        case 7:
            return 'Every_sunday';
        case 8:
            return 'Monthly';
        case 9:
            return 'yearly';
        case 10:
            return 'specific_date_time';
        default:
            return '';
    }
};
exports.NotificationFreq = NotificationFreq;
var routeResponseConverter = function (res) {
    var convertedRes = [];
    res.map(function (plannerResObj) {
        var plannerObj = {
            created_by: plannerResObj.created_by ? plannerResObj.created_by : '',
            created_date_time: plannerResObj.created_date_time
                ? plannerResObj.created_date_time
                : '',
            failed_reason: plannerResObj.failed_reason
                ? plannerResObj.failed_reason
                : '',
            generated_date_time: plannerResObj.generated_date_time
                ? (0, exports.shipmentNormaldateandTimeFormatte)(plannerResObj.generated_date_time)
                : '',
            initiated_date_time: plannerResObj.initiated_date_time
                ? (0, exports.shipmentNormaldateandTimeFormatte)(plannerResObj.initiated_date_time)
                : '',
            lab_code: plannerResObj.lab_code ? plannerResObj.lab_code : '',
            radius: plannerResObj.radius ? plannerResObj.radius : '',
            rejection_comments: plannerResObj.rejection_comments
                ? plannerResObj.rejection_comments
                : '',
            route_assignment_type: plannerResObj.route_assignment_type
                ? plannerResObj.route_assignment_type
                : '',
            route_id: plannerResObj.route_id ? plannerResObj.route_id : '',
            speed: plannerResObj.speed ? plannerResObj.speed : '',
            status: plannerResObj.status ? plannerResObj.status : '',
            id: plannerResObj.id ? plannerResObj.id : '',
        };
        convertedRes.push(plannerObj);
    });
    console.log(convertedRes, 'convertedRes');
    return convertedRes;
};
exports.routeResponseConverter = routeResponseConverter;
var convertHubListFromResponse = function (res) {
    console.log(res, 'kjhgfd');
    var convertedHubListArr = [];
    res === null || res === void 0 ? void 0 : res.map(function (resObj) {
        var convertedHubList = {
            hub_code: resObj.hub_code ? resObj.hub_code : '',
            hub_name: resObj.hub_name ? resObj.hub_name : '',
            invoice_code: resObj.invoice_code ? resObj.invoice_code : '',
            hub_type: resObj.hub_type ? resObj.hub_type : '',
            hub_state: resObj.hub_state ? resObj.hub_state : '',
            hub_city: resObj.hub_city ? resObj.hub_city : '',
            lab_code: resObj.lab_code ? resObj.lab_code : '',
            base_location_address: resObj.base_location_address
                ? resObj.base_location_address
                : '',
            longitude: resObj.longitude ? resObj.longitude : '',
            latitude: resObj.latitude ? resObj.latitude : '',
            id: resObj.latitude ? resObj.latitude : '',
            address: resObj.address ? resObj.address : '',
            geofence_enabled: (resObj === null || resObj === void 0 ? void 0 : resObj.geofence_enabled) ? resObj.geofence_enabled : 0,
            geofence_radius: (resObj === null || resObj === void 0 ? void 0 : resObj.geofence_radius) ? resObj.geofence_radius : 0,
        };
        convertedHubListArr.push(convertedHubList);
    });
    return convertedHubListArr;
};
exports.convertHubListFromResponse = convertHubListFromResponse;
var compareModulesToMenuOrder = function (menuList, availableModules, availableChildFEature, is_sso_user) {
    var ApprovalSubModule = [];
    var routePlannerSubModules = [];
    var visiteManagementSubModules = [];
    var reportSubModules = [];
    var intracitySubRoutes = [];
    var intercitySubRoutes = [];
    var mainAndSubMenuList = [];
    var filteredMainModules = [];
    availableModules.map(function (avaiObj) {
        menuList.map(function (mainModule) {
            if (!mainModule.subMenu &&
                avaiObj.moduleName == mainModule.moduleName &&
                !is_sso_user) {
                mainAndSubMenuList.push({
                    moduleName: mainModule.moduleName,
                    path: mainModule.path,
                    menuicon: mainModule.menuicon,
                });
            }
            if (mainModule.subMenu && mainModule.subMenu.length > 0) {
                mainModule.subMenu.filter(function (subModuleObje) {
                    if (subModuleObje.moduleName == avaiObj.moduleName) {
                        filteredMainModules.push(mainModule);
                    }
                });
            }
        });
        if (avaiObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.controlTower && is_sso_user) {
            mainAndSubMenuList.push({
                moduleName: 'On Demand Request',
                path: "".concat(route_path_1.PATHS.sso_ondemandRequest, "/").concat(route_path_1.PATHS.onDemandRequest),
                menuicon: 'icon-routing',
            });
        }
    });
    var filterSubModules = [];
    availableModules.map(function (avaiObj) {
        menuList.map(function (mainModule) {
            if (mainModule.subMenu && mainModule.subMenu.length > 0) {
                mainModule.subMenu.filter(function (subModuleObje) {
                    if (subModuleObje.moduleName == avaiObj.moduleName) {
                        filterSubModules.push(subModuleObje);
                    }
                });
            }
        });
    });
    availableChildFEature.map(function (aviFeatures) {
        filterSubModules.map(function (subObj, index) {
            if (subObj.subMenu && subObj.subMenu.length > 0) {
                subObj.subMenu.map(function (subModuleObje, subindex) {
                    var FEApprovals = (0, exports.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.approvals, routes_list_1.APPROVAL_MANGEMENT_FE_LIST_SUB_ROUTES);
                    var feAppFeature = [];
                    FEApprovals === null || FEApprovals === void 0 ? void 0 : FEApprovals.map(function (val) {
                        feAppFeature.push(val.featureName);
                    });
                    var VendorApprovals = (0, exports.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.approvals, routes_list_1.APPROVAL_MANGEMENT_VENDOR_LIST_SUB_ROUTES);
                    var VendorAppFeature = [];
                    VendorApprovals === null || VendorApprovals === void 0 ? void 0 : VendorApprovals.map(function (val) {
                        VendorAppFeature.push(val.featureName);
                    });
                    if (subModuleObje.moduleName == 'FE' &&
                        !feAppFeature.includes(menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_1_Pending_List)) {
                        filterSubModules[index].subMenu[subindex].path = "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.levelTwoPendingApproval);
                    }
                    if (subModuleObje.moduleName == menu_constants_1.FEATURE_CONSTANTS.sub_vendor &&
                        !VendorAppFeature.includes(menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_1_Pending_List)) {
                        filterSubModules[index].subMenu[subindex].path = "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendor, "/").concat(route_path_1.PATHS.levelTwoPendingApproval);
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.approvals) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName)) {
                                ApprovalSubModule.push(subModuleObje);
                            }
                        });
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.Route_Planner) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName)) {
                                routePlannerSubModules.push(subModuleObje);
                            }
                        });
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.visitManagement) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName)) {
                                visiteManagementSubModules.push(subModuleObje);
                            }
                        });
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.reports) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName)) {
                                reportSubModules.push(subModuleObje);
                            }
                        });
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.intracity) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName)) {
                                intracitySubRoutes.push(subModuleObje);
                            }
                        });
                    }
                    if (subModuleObje.subModuleParentName == menu_constants_1.FEATURE_CONSTANTS.intercity) {
                        aviFeatures.features.map(function (childFeau) {
                            var _a;
                            if (childFeau.moduleName == menu_constants_1.FEATURE_CONSTANTS.intercity &&
                                ((_a = subModuleObje === null || subModuleObje === void 0 ? void 0 : subModuleObje.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(childFeau.featureName))) {
                                intercitySubRoutes.push(subModuleObje);
                            }
                        });
                    }
                });
            }
        });
    });
    var removeDuplicateModules = lodash_1.default.uniqBy(filteredMainModules, 'moduleName');
    var uniqueModulesName = [];
    removeDuplicateModules.map(function (obj) {
        filterSubModules.forEach(function (val) {
            if (obj.moduleName == val.parentModule) {
                if (mainAndSubMenuList.length > 0) {
                    mainAndSubMenuList.map(function (newObj, index) {
                        if (newObj.moduleName.includes(val.parentModule)) {
                            mainAndSubMenuList[index]['subMenu'] = __spreadArray(__spreadArray([], newObj.subMenu, true), [
                                {
                                    moduleName: val.moduleName,
                                    parentModule: val.parentModule,
                                    path: val.path,
                                    menuicon: val.menuicon,
                                    chaildFetures: val.chaildFetures && val.chaildFetures,
                                },
                            ], false);
                        }
                        else {
                            if (!uniqueModulesName.includes(obj.moduleName)) {
                                uniqueModulesName.push(obj.moduleName);
                                mainAndSubMenuList.push({
                                    moduleName: obj.moduleName,
                                    path: obj.path,
                                    menuicon: obj.menuicon,
                                    subMenu: [
                                        {
                                            moduleName: val.moduleName,
                                            parentModule: val.parentModule,
                                            path: val.path,
                                            menuicon: val.menuicon,
                                        },
                                    ],
                                });
                            }
                        }
                    });
                }
                else {
                    mainAndSubMenuList.push({
                        moduleName: obj.moduleName,
                        path: obj.path,
                        menuicon: obj.menuicon,
                        subMenu: [
                            {
                                moduleName: val.moduleName,
                                parentModule: val.parentModule,
                                path: val.path,
                                menuicon: val.menuicon,
                                chaildFetures: val.chaildFetures && val.chaildFetures,
                            },
                        ],
                    });
                }
            }
        });
    });
    mainAndSubMenuList.map(function (mainObj, mainIndex) {
        if (mainObj.subMenu && mainObj.subMenu.length > 0) {
            mainObj.subMenu.forEach(function (subObj, index) {
                if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.approvals) {
                    var removedDuplicates = lodash_1.default.uniqBy(ApprovalSubModule, 'moduleName');
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu = removedDuplicates;
                }
                else if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.Route_Planner) {
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu =
                        routePlannerSubModules;
                }
                else if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.visitManagement) {
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu = lodash_1.default.uniqBy(visiteManagementSubModules, 'moduleName');
                }
                else if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.reports) {
                    var filterUniqData = lodash_1.default.uniqBy(reportSubModules, 'moduleName');
                    var sortModules = filterUniqData && lodash_1.default.sortBy(filterUniqData, function (o) { return o.id; });
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu = sortModules;
                }
                else if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.intracity) {
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu = intracitySubRoutes;
                }
                else if (subObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.intercity) {
                    mainAndSubMenuList[mainIndex].subMenu[index].subMenu = lodash_1.default.uniqBy(intercitySubRoutes, 'moduleName');
                }
            });
        }
    });
    return mainAndSubMenuList;
};
exports.compareModulesToMenuOrder = compareModulesToMenuOrder;
var resticSubRoute = function (menuList) {
    var feManagementRoutes = (0, exports.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.feManagement, routes_list_1.FE_MANGEMENT_LIST_SUB_ROUTES);
    var vendorManagementRoutes = (0, exports.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement, routes_list_1.VENDOR_MANGEMENT_LIST_SUB_ROUTES);
    var sortedFEList = lodash_1.default.orderBy(feManagementRoutes, ['id'], ['asc']);
    var sortedVendorList = lodash_1.default.orderBy(vendorManagementRoutes, ['id'], ['asc']);
    menuList &&
        menuList.map(function (menuObj, mainIndex) {
            if (menuObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.feManagement) {
                if (menuObj.subMenu && menuObj.subMenu.length > 0) {
                    menuObj.subMenu.map(function (feSubMenu, index) {
                        if (feSubMenu.moduleName == menu_constants_1.FEATURE_CONSTANTS.feManagement) {
                            sortedFEList === null || sortedFEList === void 0 ? void 0 : sortedFEList.map(function (avObj, subIndex) {
                                var _a;
                                if (!((_a = feSubMenu === null || feSubMenu === void 0 ? void 0 : feSubMenu.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(avObj.featureName))) {
                                    menuList[mainIndex].subMenu[index].path = "".concat(route_path_1.PATHS.fe, "/").concat(sortedFEList[0].path);
                                }
                            });
                        }
                    });
                }
            }
            else if (menuObj.moduleName == menu_constants_1.FEATURE_CONSTANTS.Master_Management) {
                if (menuObj.subMenu && menuObj.subMenu.length > 0) {
                    menuObj.subMenu.map(function (vendorSubMenu, index) {
                        if (vendorSubMenu.moduleName == menu_constants_1.FEATURE_CONSTANTS.vendorManagement) {
                            sortedVendorList === null || sortedVendorList === void 0 ? void 0 : sortedVendorList.map(function (avObj, subIndex) {
                                var _a;
                                if (!((_a = vendorSubMenu === null || vendorSubMenu === void 0 ? void 0 : vendorSubMenu.chaildFetures) === null || _a === void 0 ? void 0 : _a.includes(avObj.featureName))) {
                                    menuList[mainIndex].subMenu[index].path = "".concat(route_path_1.PATHS.vendorlist, "/").concat(sortedVendorList[0].path);
                                }
                            });
                        }
                    });
                }
            }
        });
    return menuList;
};
exports.resticSubRoute = resticSubRoute;
var convertOndemandPickupsListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (roleObj) {
        var convertedResObj = {
            first_name: roleObj.first_name ? roleObj.first_name : '',
            last_name: roleObj.last_name ? roleObj.last_name : '',
            fe_id: roleObj.fe_id ? roleObj.fe_id : '',
            mobile: roleObj.mobile ? roleObj.mobile : '',
            eta: roleObj.eta ? roleObj.eta : '',
            warehouse_code: roleObj.warehouse_code ? roleObj.warehouse_code : '',
            invoice_code: roleObj.invoice_code ? roleObj.invoice_code : '',
            request_raising_time: roleObj.request_raising_time
                ? roleObj.request_raising_time
                : '',
            center_name: roleObj.center_name ? roleObj.center_name : '',
            center_type: roleObj.center_type ? roleObj.center_type : '',
            route_type: roleObj.route_type ? roleObj.route_type : '',
            lab_code: roleObj.lab_code ? roleObj.lab_code : '',
            lab_name: roleObj.lab_name ? roleObj.lab_name : '',
            center_state: roleObj.center_state ? roleObj.center_state : '',
            center_city: roleObj.center_city ? roleObj.center_city : '',
            visit_status: roleObj.visit_status ? roleObj.visit_status : '',
            created_by: roleObj.created_by ? roleObj.created_by : '',
            updated_by: roleObj.updated_by ? roleObj.updated_by : '',
        };
        convertedRes.push(convertedResObj);
    });
    return convertedRes;
};
exports.convertOndemandPickupsListFromRes = convertOndemandPickupsListFromRes;
var convertUnavailableFEListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (Obj) {
        var convertedResObj = {
            fe_id: Obj.fe_id ? Obj.fe_id : '',
            fe_name: Obj.first_name
                ? (0, exports.concatFirstNameLastName)(Obj.first_name, Obj.last_name)
                : 'vb',
            fe_type: Obj.role_id ? Obj.role_id : '',
            route_type: Obj.routing_assignment ? Obj.routing_assignment : '',
            lab_id: Obj.lab_id ? Obj.lab_id : '',
            state: Obj.center_state ? Obj.center_state : '',
            city: Obj.center_city ? Obj.center_city : '',
            vendor_name: Obj.vendor_name ? Obj.vendor_name : '',
            mobile: Obj.mobile ? Obj.mobile : '',
            center_address: Obj.center_address ? Obj.center_address : '',
        };
        convertedRes.push(convertedResObj);
    });
    return convertedRes;
};
exports.convertUnavailableFEListFromRes = convertUnavailableFEListFromRes;
var convertFeAttendanceReportListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (resObj) {
        var feObj = {
            vendor_name: resObj.vendor_name ? resObj.vendor_name : '',
            fe_name: resObj.fe_name ? resObj.fe_name : '',
            fe_type: resObj.fe_type ? resObj.fe_type : '',
            fe_route_type: resObj.fe_route_type ? resObj.fe_route_type : '',
            date: resObj.date ? resObj.date : '',
            fe_id: resObj.fe_id ? resObj.fe_id : '',
            mobile: resObj.mobile ? resObj.mobile : '',
            fe_code: resObj.fe_code ? resObj.fe_code : '',
            fe_lab_code: resObj.fe_lab_code ? resObj.fe_lab_code : '',
            login_time: resObj.login_time ? resObj.login_time : '',
            first_scheduled_visit_time: resObj.first_scheduled_visit_time
                ? resObj.first_scheduled_visit_time
                : '',
            first_actual_visit_time: resObj.first_actual_visit_time
                ? resObj.first_actual_visit_time
                : '',
            deviation: resObj.deviation ? resObj.deviation : '',
            visit_id: resObj.visit_id ? resObj.visit_id : '',
            kilometers: resObj.kilometers != null && resObj.kilometers !== ''
                ? resObj.kilometers
                : '',
            visit_date: resObj.visit_date ? resObj.visit_date : '',
            date_of_exit: resObj.date_of_exit ? resObj.date_of_exit : '',
            onboard_status: resObj.onboard_status ? resObj.onboard_status : '',
            scheduled_kms: resObj.scheduled_kms != null && resObj.scheduled_kms !== ''
                ? resObj.scheduled_kms
                : '',
            actual_kms: resObj.actual_kms != null && resObj.actual_kms !== ''
                ? resObj.actual_kms
                : '',
            fe_lab_name: resObj.fe_lab_name ? resObj.fe_lab_name : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertFeAttendanceReportListFromRes = convertFeAttendanceReportListFromRes;
var convertOutstationMasterboxReportListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (resObj) {
        var feObj = {
            created_by_name: resObj.created_by_name ? resObj.created_by_name : '',
            status: resObj.status ? resObj.status : '',
            handover_date_time: resObj.handover_date_time
                ? resObj.handover_date_time
                : '',
            created_date_time: resObj.created_date_time ? resObj.created_date_time : '',
            _id: resObj._id ? resObj._id : '',
            barcode_id: resObj.barcode_id ? resObj.barcode_id : '',
            created_by_type: resObj.created_by_type ? resObj.created_by_type : '',
            created_by_id: resObj.created_by_id ? resObj.created_by_id : '',
            current_owner_id: resObj.current_owner_id ? resObj.current_owner_id : '',
            current_owner_type: resObj.current_owner_type
                ? resObj.current_owner_type
                : '',
            from_location: resObj.from_location ? resObj.from_location : '',
            tagged_lab_code: resObj.tagged_lab_code ? resObj.tagged_lab_code : '',
            tagged_lab_name: resObj.tagged_lab_name ? resObj.tagged_lab_name : '',
            movement_by: resObj.movement_by ? resObj.movement_by : '',
            to_location: resObj.to_location ? resObj.to_location : '',
            in_transit_time: resObj.in_transit_time ? resObj.in_transit_time : '',
            tagged_lab_state: resObj.tagged_lab_state ? resObj.tagged_lab_state : '',
            tagged_lab_city: resObj.tagged_lab_city ? resObj.tagged_lab_city : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertOutstationMasterboxReportListFromRes = convertOutstationMasterboxReportListFromRes;
var convertNothingToPickupReportListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (resObj) {
        var feObj = {
            center_name: resObj.center_name ? resObj.center_name : '',
            center_type: resObj.center_type ? resObj.center_type : '',
            invoice_code: resObj.invoice_code ? resObj.invoice_code : '',
            lab_code: resObj.lab_code ? resObj.lab_code : '',
            productivity: resObj.productivity ? resObj.productivity : '',
            total_assigned_rounds: resObj.total_assigned_rounds
                ? resObj.total_assigned_rounds
                : '',
            total_nothing_pickup_count: resObj.total_nothing_pickup_count
                ? resObj.total_nothing_pickup_count
                : '',
            warehouse_code: resObj.warehouse_code ? resObj.warehouse_code : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertNothingToPickupReportListFromRes = convertNothingToPickupReportListFromRes;
var convertUsersListMappingFromRes = function (res) {
    var convertedresArr = [];
    res.forEach(function (user) { return __awaiter(void 0, void 0, void 0, function () {
        var convertedres;
        return __generator(this, function (_a) {
            convertedres = {
                fullName: user.full_name ? user.full_name : '',
                userName: user.user_name ? user.user_name : '',
                email: user.email ? user.email : '',
                mobile: user.phone_number ? user.phone_number : '',
                branch: user.branch ? user.branch : '',
                id: user.id ? user.id : '',
                employee_code: user.employee_code ? user.employee_code : '',
                name: 'Selectlab',
            };
            convertedresArr.push(convertedres);
            return [2];
        });
    }); });
    return convertedresArr;
};
exports.convertUsersListMappingFromRes = convertUsersListMappingFromRes;
var flattenObjForReports = function (ob) {
    var result = {};
    var obj = {};
    ob.map(function (each, index) {
        for (var i in each) {
            if (Array.isArray(each[i])) {
                each[i].map(function (eachObj) {
                    result[eachObj.time_window_slot] = eachObj.nothing_to_pickup_count
                        ? eachObj.nothing_to_pickup_count
                        : each.successful_visits_count;
                });
            }
            else {
                result[i] = each[i];
            }
        }
    });
    for (var i in result) {
        obj[i] = result[i];
    }
    return obj;
};
exports.flattenObjForReports = flattenObjForReports;
var clientSlotwiseReportResponseConverter = function (res) {
    var convertedRes = [];
    res.map(function (reportsRes) {
        var reportsResObj = {
            center_name: reportsRes.center_name ? reportsRes.center_name : '--',
            center_type: reportsRes.center_type ? reportsRes.center_type : '--',
            invoice_code: reportsRes.invoice_code ? reportsRes.invoice_code : '--',
            lab_code: reportsRes.lab_code ? reportsRes.lab_code : '--',
            status: reportsRes.status ? reportsRes.status : '--',
            productivity: reportsRes.productivity >= 0 ? reportsRes.productivity : '--',
            total_assigned_rounds: reportsRes.total_assigned_rounds >= 0
                ? reportsRes.total_assigned_rounds
                : '--',
            total_nothing_pickup_count: reportsRes.total_nothing_pickup_count
                ? reportsRes.total_nothing_pickup_count
                : '--',
            warehouse_code: reportsRes.warehouse_code ? reportsRes.warehouse_code : '--',
            total_successful_visits_count: reportsRes.total_successful_visits_count >= 0
                ? reportsRes.total_successful_visits_count
                : '--',
            visit_date: reportsRes.visit_date ? reportsRes.visit_date : '--',
        };
        reportsRes === null || reportsRes === void 0 ? void 0 : reportsRes.rounds.map(function (round) {
            reportsResObj[round.time_window_slot] = round.nothing_to_pickup_count
                ? round.nothing_to_pickup_count
                : round.successful_visits_count;
        });
        convertedRes.push(reportsResObj);
    });
    return convertedRes;
};
exports.clientSlotwiseReportResponseConverter = clientSlotwiseReportResponseConverter;
var hourmin = function (hour, min) {
    var value = '--';
    return hour && min ? "".concat(hour, ":").concat(min) : value;
};
exports.hourmin = hourmin;
var UpdateHistory = function (res) {
    var convertedRes = [];
    res.map(function (UpdateHistoryRes) {
        console.log(UpdateHistoryRes.file.url, 'poijhg');
        var reportsResObj = {
            original_file_name: UpdateHistoryRes.original_file_name
                ? UpdateHistoryRes.original_file_name
                : '--',
            created_date_time: UpdateHistoryRes.created_date_time
                ? UpdateHistoryRes.created_date_time
                : '--',
            _id: UpdateHistoryRes._id ? UpdateHistoryRes._id : '--',
            processed: UpdateHistoryRes.processed ? UpdateHistoryRes.processed : '--',
            reason: UpdateHistoryRes.reason ? UpdateHistoryRes.reason : '--',
            file: UpdateHistoryRes && UpdateHistoryRes.file ? UpdateHistoryRes.file : '',
        };
        convertedRes.push(reportsResObj);
    });
    return convertedRes;
};
exports.UpdateHistory = UpdateHistory;
var processedkey = function (params) {
    var value = '--';
    if (params === true) {
        return 'True';
    }
    else if (params === false) {
        return 'False';
    }
    else {
        return value;
    }
};
exports.processedkey = processedkey;
var dateFormatterForMIS = function (params) {
    console.log(params, 'callfun ');
    var value = '--';
    return params ? moment(params.value).format('DD-MM-YYYY') : value;
};
exports.dateFormatterForMIS = dateFormatterForMIS;
var ShipmentTat = function (tat) {
    switch (tat) {
        case 0:
            return {
                id: 1,
                label: 'Same Day',
                value: 0,
                hours: 0,
            };
        case 1:
            return {
                id: 2,
                label: 'Next Day',
                value: 1,
                hours: 24,
            };
        case 2:
            return {
                id: 3,
                label: 'Day plus One',
                value: 2,
                hours: 48,
            };
        case 3:
            return {
                id: 4,
                label: 'Day plus Two',
                value: 3,
                hours: 72,
            };
        default:
            return '';
    }
};
exports.ShipmentTat = ShipmentTat;
var convertIntracityXaxis = function (Xaxis) {
    switch (Xaxis) {
        case '<30 min':
            return 30;
        case '60-min':
            return 60;
        case '90-min':
            return 90;
        case '120-min':
            return 120;
        case '150-min':
            return 150;
        case '>180-min':
            return 180;
        case '210-min':
            return 210;
        default:
            return '';
    }
};
exports.convertIntracityXaxis = convertIntracityXaxis;
var convertOntimePickXaxis = function (Xaxis) {
    switch (Xaxis) {
        case '<15 min':
            return 'less_than_15';
        case '30-min':
            return '15_to_30';
        case '60-min':
            return '30_to_60';
        case '90-min':
            return '60_to_90';
        case '120-min':
            return '90_to_120';
        case '>120-min':
            return 'above_120';
        default:
            return '';
    }
};
exports.convertOntimePickXaxis = convertOntimePickXaxis;
var HeadingBasedOnEvent = function (event) {
    switch (event) {
        case 'registration_Timestamp_to_Pickup':
            return 'AVG REGISTRATION TIMESTAMP TO PUP GRAND TOTAL ';
        case 'registration_Timestamp_to_lab_handover':
            return 'AVG REGISTRATION TIMESTAMP TO LAB HANDOVER GRAND TOTAL ';
        case 'registration_Timestamp_to_lab_receiving':
            return 'AVG REGISTRATION TIMESTAMP TO LAB RECEIVING GRAND TOTAL ';
        case 'pickupt_to_lab_handover':
            return 'AVG PUP TO LAB HANDOVER GRAND TOTAL ';
        case 'pickup_to_lab_receiving':
            return 'AVG PUP TO LAB RECEIVING GRAND TOTAL ';
        case 'lab_handover_to_lab_receiving':
            return 'AVG LAB HANDOVER TO LAB RECEIVING GRAND TOTAL ';
        default:
            return '';
    }
};
exports.HeadingBasedOnEvent = HeadingBasedOnEvent;
var ReplacetrueFalseCoverter = function (params) {
    var value = '--';
    if (params === 1) {
        return 'YES';
    }
    else if (params === 0) {
        return 'NO';
    }
    else {
        return value;
    }
};
exports.ReplacetrueFalseCoverter = ReplacetrueFalseCoverter;
exports.staticdata = [
    {
        auditee_type: 'CC',
        auditee_id: 'LPP1984',
        auditor_type: 'FE',
        auditor_id: 'CLA670695',
        answers: [
            {
                image: {
                    name: '64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                    url: 'https://uat-lpl-logistics.s3.ap-south-1.amazonaws.com/64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                },
            },
            {
                image: {
                    name: '64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                    url: 'https://uat-lpl-logistics.s3.ap-south-1.amazonaws.com/64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                },
            },
            {
                image: {
                    name: '64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                    url: 'https://uat-lpl-logistics.s3.ap-south-1.amazonaws.com/64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                },
            },
            {
                image: {
                    name: '64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                    url: 'https://uat-lpl-logistics.s3.ap-south-1.amazonaws.com/64e706d99dd07e4117fe2751/2023-08-24/audit/64e706d99dd07e4117fe2751_audit_1692862810.jpg',
                },
            },
        ],
        completed_date_time: '2023-08-24T07:40:10.668Z',
        auditee_name: 'SUN VIEW',
        auditee_lab_code: 'L51',
        auditor_name: 'SHAIK RAZZAQ',
        auditor_mobile: '8247541168',
    },
];
var convertAdditAnswerListFromRes = function (res, question_type) {
    var convertedRes = [];
    res.map(function (Obj) {
        var convertedResObj = {
            auditee_id: Obj.auditee_id ? Obj.auditee_id : '',
            auditee_name: Obj.auditee_name ? Obj.auditee_name : '',
            auditee_lab_code: Obj.auditee_lab_code ? Obj.auditee_lab_code : '',
            auditor_id: Obj.auditor_id ? Obj.auditor_id : '',
            auditor_name: Obj.auditor_name ? Obj.auditor_name : '',
            auditor_mobile: Obj.auditor_mobile ? Obj.auditor_mobile : '',
            completed_date_time: Obj.completed_date_time ? Obj.completed_date_time : '',
            answers: question_type === 'Short Answer'
                ? Obj.answers
                    ? Obj.answers.map(function (e) {
                        console.log(e.free_text, ';lkjhgfdsd');
                        return e.free_text;
                    })
                    : ''
                : question_type === 'Mobile Number'
                    ? Obj.answers
                        ? Obj.answers.map(function (e) {
                            console.log(e.free_text, ';lkjhgfdsd');
                            return e.free_text;
                        })
                        : ''
                    : question_type === 'Linear Scale'
                        ? Obj.answers
                            ? Obj.answers.map(function (e) {
                                console.log(e.rating, ';lkjhgfdsd');
                                return e.rating;
                            })
                            : ''
                        : Obj.answers
                            ? Obj.answers.map(function (e) {
                                console.log(e.answer_choices, ';lkjhgfdsd');
                                return e.answer_choices;
                            })
                            : '',
            image: Obj.answers
                ? Obj.answers.map(function (e) {
                    return e.image;
                })
                : '',
        };
        console.log(convertedResObj, 'convertedResObj');
        convertedRes.push(convertedResObj);
    });
    return convertedRes;
};
exports.convertAdditAnswerListFromRes = convertAdditAnswerListFromRes;
var isValueZero = function (value) {
    var empty_value = '--';
    if (value === 0) {
        return value;
    }
    else if (value > 0) {
        return value;
    }
    else {
        return empty_value;
    }
};
exports.isValueZero = isValueZero;
var sortObjectKeys = function (data) {
    var sortData = {};
    Object.keys(data).sort().forEach(function (v, i) {
        sortData[v] = data[v];
    });
    return sortData;
};
exports.sortObjectKeys = sortObjectKeys;
var convertETRFResponse = function (res) {
    var convertedResponse = [];
    res.forEach(function (etrfRecord) {
        etrfRecord['status'] = (etrfRecord === null || etrfRecord === void 0 ? void 0 : etrfRecord.posted_to_1xview) ? 'true' : 'false';
        etrfRecord['converted_created_date_time'] = (etrfRecord === null || etrfRecord === void 0 ? void 0 : etrfRecord.created_date_time) ? (0, exports.normaldateandTimeFormatte)(etrfRecord.created_date_time) : '--';
        etrfRecord['fe_name'] = (etrfRecord === null || etrfRecord === void 0 ? void 0 : etrfRecord.created_by_name) ? etrfRecord.created_by_name.split("-")[0] : '--';
        etrfRecord['fe_mobile'] = (etrfRecord === null || etrfRecord === void 0 ? void 0 : etrfRecord.created_by_name) ? etrfRecord.created_by_name.split("-")[1] : '--';
        etrfRecord['converted_lab_handover_time'] = (etrfRecord === null || etrfRecord === void 0 ? void 0 : etrfRecord.lab_handover_time) ? (0, exports.etrfdateandTimeFormatte)(etrfRecord.lab_handover_time) : '--';
        convertedResponse.push(etrfRecord);
    });
    return convertedResponse;
};
exports.convertETRFResponse = convertETRFResponse;
var convertETRFFEList = function (response) {
    var feTypesLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        feTypesLabelAndValue.push({
            id: data.id,
            value: data.id,
            label: "".concat(data.first_name || '', " ").concat(data.last_name || '', ",").concat(data.fe_id, ",").concat(data.mobile || ''),
            fe_type: data.fe_type,
            FE_id: data.fe_id,
            centre_city: data.working_city,
            centre_state: data.working_state,
            mobile: data.mobile,
            name: "".concat(data.first_name || '', " ").concat(data.last_name || ''),
            lab_code: data.lab_id,
            hub_code: data.hub_id,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertETRFFEList = convertETRFFEList;
var convertUltraStaticCCList = function (response) {
    var ccLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        ccLabelAndValue.push({
            id: data.id,
            value: data.warehouse_code,
            label: "".concat(data.invoice_code ? "".concat(data.invoice_code, " / ") : '').concat(data.center_name ? "".concat(data.center_name, " / ") : '').concat(data.warehouse_code ? "".concat(data.warehouse_code) : ''),
            center_name: data.center_name,
            center_city: data.center_city,
            center_state: data.center_state,
        });
    }
    return ccLabelAndValue;
};
exports.convertUltraStaticCCList = convertUltraStaticCCList;
var convertUltraStaticFEList = function (response) {
    var ccLabelAndValue = [];
    var ultrastatic_route_status_types = store_1.store.getState().appConfigList.ultra_static_route_status_type;
    var _loop_1 = function (data) {
        var status_1 = ultrastatic_route_status_types.filter(function (route) { return route.id === data.status; });
        ccLabelAndValue.push({
            id: data.id,
            name: "".concat(data.first_name, " ").concat(data.last_name),
            fe_code: "".concat(data.fe_code),
            mobile: data.mobile,
            lab_code: data.lab_code,
            onboard_status: data.onboard_status,
            status: status_1[0].label,
            fe_id: data.fe_id
        });
    };
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        _loop_1(data);
    }
    return ccLabelAndValue;
};
exports.convertUltraStaticFEList = convertUltraStaticFEList;
var convertUltraStaticFEResponse = function (response) {
    var convertedResponse = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        convertedResponse.push({
            id: data.id,
            label: "".concat(data.fe_code, ",").concat(data.name, ",").concat(data.mobile),
            value: data.fe_id
        });
    }
    return convertedResponse;
};
exports.convertUltraStaticFEResponse = convertUltraStaticFEResponse;
var convertVisitsInfoResponse = function (response) {
    var _a;
    var visit_types = store_1.store.getState().appConfigList.visitTypesList;
    var convertedResponse = [];
    var _loop_2 = function (data) {
        var status_2 = visit_types === null || visit_types === void 0 ? void 0 : visit_types.filter(function (route) { return route.id === data.visit_type; });
        convertedResponse.push({
            warehouse_code: data.warehouse_code,
            center_name: data.center_name,
            visit_type: status_2 ? (_a = status_2[0]) === null || _a === void 0 ? void 0 : _a.label.replace(/_/g, ' ') : '--',
            scheduled_start_time: data.scheduled_start_time
        });
    };
    for (var _i = 0, _b = response && response; _i < _b.length; _i++) {
        var data = _b[_i];
        _loop_2(data);
    }
    return convertedResponse;
};
exports.convertVisitsInfoResponse = convertVisitsInfoResponse;
var convertFEInfoResponse = function (response) {
    var convertedResponse = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        convertedResponse.push({
            fe_code: data.fe_id,
            fe_name: "".concat(data.fe_first_name || '', " ").concat(data.fe_last_name || ''),
            fe_mobile: data.mobile,
            scheduled_start_time: data.scheduled_start_time
        });
    }
    return convertedResponse;
};
exports.convertFEInfoResponse = convertFEInfoResponse;
var convertFEListResponse = function (response) {
    var feTypesLabelAndValue = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        feTypesLabelAndValue.push({
            id: data.id,
            value: data.id,
            label: "".concat(data.mobile || '', " / ").concat(data.first_name || '', " ").concat(data.last_name || '', " / ").concat(data.fe_id),
            fe_type: data.fe_type,
            FE_id: data.fe_id,
            centre_city: data.working_city,
            centre_state: data.working_state,
            mobile: data.mobile,
            name: "".concat(data.first_name || '', " ").concat(data.last_name || ''),
            lab_code: data.lab_id,
            hub_code: data.hub_id,
        });
    }
    return feTypesLabelAndValue;
};
exports.convertFEListResponse = convertFEListResponse;
var convertCompanyConfigResponse = function (companyResponse) {
    var result = [];
    companyResponse.forEach(function (companyDetails) {
        var obj = {
            id: companyDetails.id,
            label: companyDetails.data_area_id,
            value: companyDetails.id
        };
        result.push(obj);
    });
    return result;
};
exports.convertCompanyConfigResponse = convertCompanyConfigResponse;
var convertToISDDateFormat = function (params) {
    return moment(params).format("".concat(appConstants_1.geoFenceDateFormatt));
};
exports.convertToISDDateFormat = convertToISDDateFormat;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.api_urls = void 0;
exports.api_urls = {
    LOGIN_BY_PASSWORD: '/logistics/login',
    LOGOUT: '/logistics/logout',
    LOGIN_OTP: '/auth/otp',
    LOGIN_BY_OTP: '/auth/login-by-otp',
    APP_CONFIG: '/config',
    APP_INVENTORY: '/user/inventory',
    COUNTRY: '/address/country',
    FE_LIST: '/user/list',
    APPROVE_FE: 'fe-onboard/process',
    DELETE_DRAFTED_FE: '/user/delete-draft-fe',
    STATE: '/address/state',
    CITIES: '/address/city',
    FE_PROFILE_DETAILS: '/user/profile',
    onLeaveToday: '/leaves/list',
    FE_CREATION: '/user/create',
    LEAVES_LIST: '/leaves/list',
    PRECIOUS_SAMPLES_LIST: '/bag/precious-sample/list',
    CREATE_PRECIOUS_SAMPLES_LIST: '/bag/precious-sample/create',
    DELETE_PRECIOUS_SAMPLES_LIST: '/bag/precious-sample/delete',
    UPDATE_PRECIOUS_SAMPLES_LIST: '/bag/precious-sample/update',
    PRECIOUS_SAMPLES_TYPE_LIST: '/bag/precious-sample/sample-type-list',
    PRECIOUS_SAMPLES_cONTAINER_TYPE_LIST: '/bag/precious-sample/container-mat-code-list',
    PRECIOUS_SAMPLES_CAT_CCODE_LIST: '/bag/precious-sample/test-cat-code-list',
    LAB_LIST: '/lab/list',
    USER_EXIT: '/user/exit',
    UPLOAD_FILE: '/bag/fileupload',
    LEAVES_CREATE: '/leaves/create',
    LEAVES_UPDATE: '/leaves/update',
    ASSIGN_INVENTORY: '/inventory/management',
    LEAVE_REASONS__LIST: '/leaves/list-reasons',
    FE_SLOTS: '/roster/fe-slots',
    ALL_TIME_SLOTS: '/roster/slot-master',
    CREATE_FE_TIME_SLOT: '/roster/create-fe-slot',
    WEEK_DAYS_FOR_SLOTS: '/roster/weekday-master',
    FEATURES: '/features',
    VALIDATE_PINCODE: '/address/validate-pincode',
    IMAGE_URL: '/bag/s3-signed-url',
    AUDIT_LIST: 'audit/list',
    DELETE_AUDIT: 'audit/delete',
    BOOKMARK_AUDIT: '/audit/favourite',
    ASSIGNED_INVENTORY: '/inventory/fe-inventory',
    VALIDATE_MOBILENO: '/user/is-mobile-exist',
    ONE_LEVEL_APPROVAL_LIST: '/fe-onboard/level-1/pending/list',
    LEVEL_ONE_PENDING_LIST: '/fe-onboard/level-1/pending/list',
    LEVEL_TWO_PENDING_LIST: '/fe-onboard/level-2/pending/list',
    LEVEL_ONE_REJECT: '/fe-onboard/reject/process',
    APPROVAE_LEVEL_ONE: '/fe-onboard/level-1/approval/process',
    APPROVAL_LEVEL_TWO: '/fe-onboard/level-2/approval/process',
    VENDOR_LIST: '/vendor/list',
    ROLE_LIST: '/role',
    AUDIT_PENDING_DETAILS: '/audit/pending-auditee',
    CREATE_AUDIT: '/audit/create',
    AUDIENCE_UNDER_CITIES: '/audit/target-audience',
    ASSIGN_AUDIT: '/audit/assignment',
    AUDIT_DETAILS: '/audit/details-by-id',
    AUDIT_DETAILS_INFO: '/audit/question-stats',
    AUDIT_ANSWER_DETAILS: '/audit/answer-details',
    AUDIT_GENERATE: '/audit/answer-details-by-audit-id/generate',
    HUBS_UNDER_LAB: '/lab/hub/list',
    VENDOR_PROFILE: '/vendor/profile',
    VENDOR_ENTITY_TYPE: '/vendor/list',
    VENDOR_CREATION: '/vendor/create',
    VENDOR_PROFILE_DETAILS: '/vendor/profile',
    VEDNOR_LIST: '/vendor/list',
    VENDOR_FE_PROFILE_LIST: '/vendor/fe-list',
    EXIT_VEDNOR: '/vendor/exit',
    DELETE_VENDOR: '/vendor/draft-delete',
    VENDOR_FE_LIST: './vendor/fe-list',
    VENODR_LEVEL_ONE_APPROVAL: '/vendor/level-1/approval/process',
    VENDOR_LEVEL_TWO_APPROVAL: '/vendor/level-2/approval/process',
    REFRESH_TOKEN: '/logistics/refresh-token',
    VENDOR_REJECT: '/vendor/reject/process',
    GET_CAPTCHA: '/generate-captcha',
    SAMPLE_TRACKING: '/bag/sample-tracking',
    BAG_DETAILS: '/bag/details',
    SAMPLE_BAG_JOURNEY: '/bag/sample-journey',
    BAG_JOURNEY: '/bag/bag-journey',
    CASH_COLLECTION_REPORT: 'reports/cash',
    BAGGING_LIFECYCLE: '/reports/sample',
    BAGGING_LIFECYCLE_Graphs: '/reports/sample-flat',
    CASH_PENDING_REPORT: 'reports/cash-pending',
    LAB_VISITES: '/visits/lab-visits',
    AUTO_ROUTING_COUNTS: 'auto-routing/metrics',
    TOTAL_ACTIVE_CLIENTS_LIST: 'auto-routing/clients-list',
    TOTAL_FE: '/auto-routing/total-fe',
    LOGOUT_FE_LIST: '/auto-routing/logout-fe-list',
    FE_WORKFORCE: '/reports/auto-routing/fe-workforce',
    DYNAMIC_CLIENTS: '/reports/auto-routing/dynamic-clients',
    TOTAL_PICKUPS: '/auto-routing/pickups-data',
    ONDEMAND_PICKUPS: '/auto-routing/on-demand-data',
    RETRY_PROCESSING: '/visits/retry-processing',
    ADD_VISITS: '/visits/read-csv',
    VISITS_LIST: '/visits/list',
    UN_ASSIGNED_VISITS: '/visits/unassigned',
    AVAILABLE_FES: '/visits/available-fes',
    SSO_GENERATION: '/vendor/sso/hash',
    SSO_LOGIN: '/vendor/sso/login',
    MIS_REPORTS_EVENT_PERFORMANCE: '/reports/mis/event-performance',
    MIS_REPORTS_FE_ROUTE: '/reports/mis/fe-route',
    VENDOR_LEVEL_ONE_PENDING_LIST: '/vendor/level-1/pending/list',
    VENDOR_LEVEL_TWO_PENDING_LIST: '/vendor/level-2/pending/list',
    CRE_PROFILE: '/cre/profile',
    CRE_USERS_LIST: '/cre/users',
    CRE_MOBILE_EXIST: '/cre/is-mobile-exist',
    CRE_CREATE_USER: '/cre/create',
    CRE_UPDATE_USER: '/cre/update',
    WAREHOUSE_CODE_LIST: '/static-routes/warehouse-code-list',
    ONDEMAND_REQUEST: '/visits/on-demand',
    ULTRATSTATIC_ONDEMAND_REQUEST: '/visits/utrastatic/on-demand',
    USER_MANAGEMENT_LIST: '/user/list',
    USER_MANAGEMENT_EDIT: '/user/',
    USER_MANAGEMENT_DOWNLOAD: '/user-logistics/download',
    MYREPORTS_LIST: '/reports/download/list',
    CASHCOLLECTION_AUTOGENERATE: 'reports/cash/generate',
    BAGGINGLIFECYCLE_AUTOGENERATE: 'reports/sample/generate',
    BAGGINGLIFECYCLE_AUTOGENERATENEW: 'reports/sample-flat/generate',
    MIS_AUTOGENERATE: 'reports/mis/generate',
    MIS_FEROUTE_AUTOGENERATE: 'reports/mis/fe-route-generate',
    ACTIVEFE_EDIT: '/user/update',
    AUDITORS_UNDER_CC: '/audit/available-fes',
    PROFILE_DETAILS: '/vendor/profiledetails/',
    CC_SCHEDULE: '/reports/cc-schedule',
    ONDEMAND_REJECT: '/reports/on-demand/reject',
    VENDOR_USER_DETAILS: 'vendor/user-details',
    PICKUP_STATIC_LIST: '/reports/auto-routing/static-pickups',
    STATIC_CLIENTS_LIST: '/reports/auto-routing/static-clients',
    DOWNLOADREPORT_HISTORY: '/reports/download-history',
    FEMANAGEMENT_AUTOGENERATE: '/user/report/generate',
    WAREHOUSE_CODE: '/static-routes/warehouse-code-list',
    CC_TIMESLOT_LIST: '/masters/center-roster/list',
    CC_CREATE: '/masters/center-roster/schedules-create',
    CC_EDIT: '/masters/center-roster/schedule-list',
    CHANGE_PASSWORD: '/logistics/change-password',
    FORGOT_PASSWORD: '/logistics/forgot-password',
    VALIDATE_FORGET_PASSWORD: '/hash/validate/forgot-password',
    RESET_PASSWORD: '/logistics/reset-password',
    FE_LIST_SWAP: '/static-routes/fe-list',
    FE_LIST_ON_LABCODE: '/visits/fe-list-by-lab',
    FE_SWAP: '/visits/fe-swap',
    ASSIGN_ONDEMAND_REQUEST_LIST: '/visits/available-fes',
    ASSIGN_ONDEMAND_REQUEST: '/visits/unassigned',
    USER_LOGISTICS_LIST: '/user-logistics/list',
    MOBILEANDEMAILVALIDATE: '/user-logistics/validate',
    CREATEUSERLOGISTIC: '/user-logistics/create',
    ROUTE_PLANNER_LIST: '/route-planner/status-list',
    ROUTE_PLANNER_CREATE: '/route-planner/new-route',
    ROUTE_PLANNER_CONFIGARATION: '/route-planner/configurations',
    ROUTE_PLANEER_PROFILE_DETAILS: '/route-planner/route-list',
    ROUTE_PLANEER_UPDATE: '/route-planner/update',
    APPROVE_REJECT_ROUTE: '/route-planner/approvals',
    CASH_PENDING_REPORT_EXCELGENERATION: '/reports/pending-cash/generate',
    hub_master_list: '/hub-master-roster/list',
    deleteHub: '/hub-master-roster/delete',
    CREATE_HUB: '/hub-master-roster/create',
    CREATE_HUB_SLOTS: '/hub-master-roster/schedules/create',
    SCHEDULE_LIST: '/hub-master-roster/schedule-list',
    EDIT_HUB: '/hub-master-roster/update',
    Lab_Master_List: '/masters/center-roster/lab-list',
    Lab_edit_data: '/masters/center-roster/lab-details',
    Lab_CREATE: '/masters/center-roster/lab-schedules-create',
    On_Demand_time_slots: 'reports/on-demand/time-slots',
    ALL_VISITS: 'visits-report/all',
    ASSIGNED_COMPLETED_REPORTS: 'visits-report/assigned-completed',
    DOWNLOAD_VISITS: 'visits-report/download',
    master_box_list: '/masterbox/list',
    master_box_details: '/masterbox/tracking/details',
    master_box_journey: '/masterbox/journey',
    master_slots: 'roster/master-slots',
    ETRF_LIST: '/bag/get-etrf-data',
    ETRF_UPDATED_LIST: '/bag/labnumber/list',
    ETRF_DETAILS: 'bag/etrf-details',
    ETRF_GENERATE: 'bag/etrf/generate',
    auto_routing_clients_data_generate: 'auto-routing/clients-data/generate',
    auto_routing_total_fe_generate: 'auto-routing/total-fe/generate',
    auto_routing_on_demand_generate: 'auto-routing/on-demand/generate',
    auto_routing_total_pickup_generate: 'auto-routing/total-pickup/generate',
    auto_routing_fe_list_generate: 'auto-routing/logout-fe-list/generate',
    visits_by_pass: 'visits/by-pass',
    Region_List: 'region-management/regions-list',
    User_Mapping_List: 'region-management/users-region-list',
    Region_Cretion: 'region-management/create',
    User_Region_Cretion: 'region-management/user-region-mapping',
    User_Region_Update: 'region-management/users',
    Region_Management_Update: 'region-management/labs',
    shipmentList: '/shipment/tracking/list',
    shipmentDetails: '/shipment/master-shipment/tracking/details',
    shipmentJourney: 'shipment/master-shipment/tracking/shipment-journey',
    masterShipmentJourney: '/shipment/master-shipment/tracking/master-shipment-journey',
    shipmentBarcode: 'shipment/shipment-details',
    Notification_creation: '/notifications/config/create',
    Notification_List: '/notifications/config/list',
    Notification_delete: '/notifications/config/delete',
    Notification_update: '/notifications/config/update',
    Notification_details: '/notifications/config/details',
    Notification_Count: 'notifications/count',
    Notification_Message_List: 'notifications/list',
    Notification_Message_update: 'notifications/update',
    DOWNLOAD_VISITS_LIST: '/visits/generate',
    Fe_Attendance_Report: 'reports/fe-attendence',
    Outstation_Masterbox_Report: 'reports/masterbox',
    Outstation_Masterbox_Generate: 'reports/masterbox/generate',
    NothingTo_Pickup_Report: 'reports/nothing-to-pickup',
    NothingTo_Pickup_Report_Generate: 'reports/nothing-to-pickup/generate',
    Fe_Attendance_Report_Generate: 'reports/fe-attendence/generate',
    RLABTOPLABLIST: '/reports/get-shipment-details',
    DOWMLOADRLABTOPLAB: '/reports/get-shipment-details/generate',
    SUCCESSFUL_VISIT: '/reports/successful-visits',
    SUCCESSFUL_VISIT_GENERATE: '/reports/successful-visits/generate',
    InterCity_R_and_P_Lab_Metrics: '/reports/rlab-to-plab/dashboard/count',
    InterCity_R_and_P_Lab_list: '/reports/get-shipment-details',
    oustationMasterBoxList: '/reports/masterbox',
    visits_Upload_History: 'visits/upload-history',
    Partial_Visits_Transfer: '/ultra-static/visit-transfer',
    RlabToPlab_Dashboard_List: '/reports/rlab-to-plab/dashboard/list',
    RlabToPlab_Dashboard_Download: '/reports/rlab-to-plab/dashboard/generate',
    Masterbox_Counts: '/reports/masterbox-counts',
    Intercity_tat_graph: '/reports/intracity-counts',
    Intercity_tat_sample_list: '/reports/intracity-graph-data',
    Intercity_tat_fe_list: '/reports/fe-intracity',
    Intercity_tat_fe_list_Generate: '/reports/fe-intracity/generate',
    On_Time_Pickup: '/reports/ontime-pickup-counts',
    On_Time_Pickup_List: '/reports/ontime-pickup-list',
    On_Time_Pickup_Fe_List: '/reports/fe-intracity-counts',
    On_Time_Pickup_Generate: '/reports/fe-intracity-ontime-pickup/generate',
    On_Time_Pickup_Fe_List_Download: '/reports/ontime-pickup-list/generate',
    fe_live_tracking_detials: '/user/fe-route',
    LAB_MASTER_REPORT: '/masters/center-roster/labs/generate',
    CENTER_MASTER_REPORT: '/masters/center-roster/list/generate',
    HUB_MASTER_REPORT: '/hub-master-roster/list/generate',
    CRE_REPORT: '/cre/report/generate',
    Shipment_List: '/shipment/route-master/list',
    SHIPMENT_CREATE: '/shipment/route-master/create',
    Shipment_details: '/shipment/route-master/details',
    Shipment_delete: '/shipment/route-master/delete',
    SHIPMENT_UPDATE: '/shipment/route-master/update',
    LAB_HANDOVER_GRAPH: '/reports/lab-handover-trend-ontime',
    LAB_HANDOVER_SAMPLES: '/reports/lab-handover-trend-sample',
    Shipment_download: '/shipment/route-master/list/generate',
    Precious_sample_downlaod: '/bag/precious-sample/generate',
    Intracity_graph_download: '/reports/intracity-graph-data/generate',
    SAVE_TOKEN: '/auth/add-token-to-login-history',
    REMOVE_TOKEN: '/auth/logout',
    autoRoutingActiveClientMetrcs: '/auto-routing/metrics/clients',
    autoRoutingOndemandtMetrcs: '/auto-routing/metrics/on-demand-pickups',
    autoRoutingTotalFEtMetrcs: '/auto-routing/metrics/fe',
    autoRoutingTotalPicukpstMetrcs: '/auto-routing/metrics/pickups',
    leaveTrackingDownload: '/reports/fe-leaves/generate',
    CREATE_ORC: 'orc/create',
    UPDATE_ORC: 'orc/update',
    ORC_LISTING: 'orc/list',
    ORC_APPROVE_REJECT: 'orc/approval/update',
    ORC_PROFILE_DETAILS: 'orc/details',
    ORC_DOWNLOAD: 'orc/report/generate',
    SCF_LISTING: 'scf/list',
    CREATE_SCF: 'scf/create',
    UPDATE_SCF: 'scf/update',
    SCF_DETAILS: 'scf/details',
    SCF_APPROVE_REJECT: 'scf/approval/update',
    SCF_DOWNLOAD: 'scf/report/generate',
    ULTRA_STATIC_LIST: 'ultra-static/fe-list',
    ULTRA_STATIC: 'ultra-static',
    CENTER_SLOTS: 'ultra-static/center-slots',
    LIST_UPDATE: 'ultra-static/update',
    ULTRASTATIC_DOWNLOAD: 'ultra-static/report/generate',
    COPY_VISITS: '/ultra-static/copy',
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var array_helpers_1 = require("../../../utils/array_helpers");
require("../ShimentRoute/shipmentRoute.scss");
var slotblocking_apicalls_1 = require("../../field_exicutive/slot_blocking/slotblocking.apicalls");
require("../ultra_static/ultra_static.scss");
var material_1 = require("@mui/material");
var react_hot_toast_1 = require("react-hot-toast");
var details_grid_1 = require("../../../common/DetailsGrid/details_grid");
var ultra_static_api_1 = require("../ultra_static/ultra_static_api");
var visitsList_api_calls_1 = require("./visitsList_api_calls");
var PartialVisitsTransfer = function () {
    var state = (0, react_router_1.useLocation)().state;
    var nav = (0, react_router_1.useNavigate)();
    var routing_types_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignment_type; });
    var ultrastatic_route_status_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_status_type; });
    var ultrastatic_route_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_type; });
    var ultrastatic_schedule_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_schedule_types; });
    var _a = React.useState([]), filteredFEList = _a[0], setFilteredFEList = _a[1];
    var routeType = routing_types_list.filter(function (routeType) { return routeType.label === "ULTRA_STATIC"; });
    var ultraStaticInitialValues = {
        labCode: state ? state.labcode.value : '',
        labName: state ? state.labcode.center_name : '',
        labState: state ? state.labcode.centre_state : '',
        labCity: state ? state.labcode.center_city : '',
        feMobile: state ? state.from_fe_id.mobile : '',
        feCode: state ? state.from_fe_id.FE_id : '',
        feName: state ? state.from_fe_id.name : '',
        feWorkingState: state ? state.from_fe_id.centre_state : '',
        feWorkingCity: state ? state.from_fe_id.centre_city : '',
        scheduleType: { label: 'MON - SAT', value: 1, id: 1 },
        slots: [],
    };
    var _b = React.useState(ultraStaticInitialValues), initialValues = _b[0], setInitialValues = _b[1];
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.Visits, "/").concat(route_path_1.default.PATHS.VisitsList));
    };
    var formSubmitHandler = function (values) {
        var transferVisits = [];
        var filteredVisits = values === null || values === void 0 ? void 0 : values.slots.filter(function (visit) { return !visit.isTransferred && visit.transferFE != ''; });
        if (filteredVisits.length > 0) {
            filteredVisits.forEach(function (visit) {
                var visitDetails = {
                    id: visit.routeId,
                    fe_id: visit.transferFE.id
                };
                transferVisits.push(visitDetails);
            });
            var payload = {
                fe_id: state.from_fe_id.id,
                schedule_type: values.scheduleType.value,
                transfer_visit: transferVisits
            };
            (0, visitsList_api_calls_1.submitPartialVisitsTransfer)(payload)
                .then(function (response) {
                if (response.statusCode === 200) {
                    react_hot_toast_1.default.success(response.message, { duration: 3000 });
                    navigateTOListPage();
                }
                else {
                    react_hot_toast_1.default.error(response.message, { duration: 3000 });
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message, { duration: 2000 });
            });
        }
        else {
            react_hot_toast_1.default.error('No visits are selected to submit', { duration: 3000 });
        }
    };
    var fetchSelectedFERoute = function (feId, scheduleType, setFieldValue) {
        var payloadParam = "fe_id=".concat(feId, "&schedule_type=").concat(scheduleType, "&status=1&visit_transfer=true");
        (0, ultra_static_api_1.getUltraStaticRoutes)(payloadParam)
            .then(function (res) {
            var _a, _b, _c;
            if (res.statusCode === 200) {
                if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.results.length) > 0) {
                    var slots_1 = [];
                    var scheduleType_1 = ultrastatic_schedule_types === null || ultrastatic_schedule_types === void 0 ? void 0 : ultrastatic_schedule_types.filter(function (type) { var _a; return type.id === parseInt((_a = res.data) === null || _a === void 0 ? void 0 : _a.results[0].schedule_type); });
                    (_c = (_b = res.data) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c.map(function (each, index) {
                        var routeSlots = {};
                        var visitType = ultrastatic_route_types.filter(function (routeType) { return routeType.value === parseInt(each.visit_type); });
                        var minutes = appConstants_1.MinutesUltrStatic.filter(function (minute) { return minute.value === each.eta.substring(3); });
                        var timeSlot = appConstants_1.TimeSlots.filter(function (slot) { return slot.value.substring(0, 2) === each.eta.substring(0, 2); });
                        var status = ultrastatic_route_status_types.filter(function (status) { return status.id === each.status; });
                        var transferFEDetails = {
                            id: index,
                            label: "".concat(each.tranfered_fe_first_name && each.tranfered_fe_first_name, " ").concat(each.tranfered_fe_last_name && each.tranfered_fe_last_name, " / ").concat(each.tranfered_fe_mobile && each.tranfered_fe_mobile),
                            value: index,
                        };
                        routeSlots['ccCode'] = each === null || each === void 0 ? void 0 : each.warehouse_code;
                        routeSlots['ccName'] = each === null || each === void 0 ? void 0 : each.center_name;
                        routeSlots['event'] = visitType[0].label;
                        routeSlots['hours'] = each.eta.substring(0, 2);
                        routeSlots['eta'] = each.eta;
                        routeSlots['minutes'] = minutes[0].label;
                        routeSlots['timeSlots'] = timeSlot[0].label;
                        routeSlots['status'] = status[0].label;
                        routeSlots['isError'] = false;
                        routeSlots['routeId'] = each.id;
                        routeSlots['transferFE'] = (each === null || each === void 0 ? void 0 : each.tranfered_fe_first_name) !== undefined ? transferFEDetails : '';
                        routeSlots['isTransferred'] = (each === null || each === void 0 ? void 0 : each.tranfered_fe_first_name) && true;
                        slots_1.push(routeSlots);
                    });
                    if (setFieldValue) {
                        setFieldValue('slots', slots_1);
                        setFieldValue('scheduleType', scheduleType_1[0]);
                    }
                    else {
                        setInitialValues(function (prevValues) { return (__assign(__assign({}, prevValues), { slots: slots_1, scheduleType: scheduleType_1[0] })); });
                    }
                }
                else {
                    if (setFieldValue) {
                        setFieldValue('slots', []);
                    }
                }
            }
        });
    };
    React.useEffect(function () {
        fetchSelectedFERoute(state.from_fe_id.id, 1);
        var payload = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
            route_type: routeType[0].value,
            lab_code: state.labcode.value
        };
        (0, slotblocking_apicalls_1.fetchFeOnSelectedFeType)(payload)
            .then(function (res) {
            var ultrastaticList = (0, array_helpers_1.convertETRFFEList)(res);
            var filteredList = ultrastaticList === null || ultrastaticList === void 0 ? void 0 : ultrastaticList.filter(function (fe) { return fe.FE_id !== state.from_fe_id.FE_id; });
            setFilteredFEList(filteredList);
        })
            .catch(function (e) { return console.log(e); });
    }, [state]);
    var timeSlots = function (values, setFieldValue, setFieldTouched, errors, touched, index) {
        var _a, _b;
        return (React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3, alignItems: "center" },
            React.createElement(Grid_1.default, { item: true, xs: 1.8, lg: 1.8 },
                React.createElement(text_filed_component_1.default, { name: "event", value: values === null || values === void 0 ? void 0 : values.slots[index].event, isDisabled: true, label: 'Event Type', placeholder: "Event", required: true })),
            React.createElement(Grid_1.default, { item: true, xs: 1.3, lg: 1.3 },
                React.createElement(text_filed_component_1.default, { name: "ccCode", value: values === null || values === void 0 ? void 0 : values.slots[index].ccCode, placeholder: 'CC/HUB/Lab Code', isDisabled: true, label: 'CC/HUB/Lab Code', required: true })),
            React.createElement(Grid_1.default, { item: true, xs: 2.5, lg: 2.5 },
                React.createElement(text_filed_component_1.default, { name: "ccName", placeholder: 'CC/HUB/Lab Name', label: 'CC/HUB/Lab Name', isDisabled: true, value: (_a = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _a === void 0 ? void 0 : _a.ccName, key: values.ccName })),
            React.createElement(Grid_1.default, { item: true, xs: 1.6, lg: 1.6 },
                React.createElement(material_1.Box, null,
                    React.createElement(text_filed_component_1.default, { name: "timeSlots", value: values === null || values === void 0 ? void 0 : values.slots[index].timeSlots, isDisabled: true, label: 'Time slot', placeholder: "Time Slots", required: true }))),
            React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                React.createElement(text_filed_component_1.default, { name: "hours", placeholder: "Hours", label: "Hours", value: values === null || values === void 0 ? void 0 : values.slots[index].hours, isDisabled: true, required: true })),
            React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                React.createElement(text_filed_component_1.default, { name: "minutes", value: values === null || values === void 0 ? void 0 : values.slots[index].minutes, placeholder: "Minutes", label: 'Minutes', required: true, isDisabled: true })),
            React.createElement(Grid_1.default, { item: true, xs: 2.7, lg: 2.7, className: "DeleteIcon" },
                React.createElement(drop_down_component_1.default, { disableClearable: true, isDisabled: (_b = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _b === void 0 ? void 0 : _b.isTransferred, name: "transferFE", value: values === null || values === void 0 ? void 0 : values.slots[index].transferFE, dropDownList: filteredFEList.length > 0 ? filteredFEList : [], placeholder: "Transfer FE", label: "Transfer FE", required: true, onChange: function (val) {
                        setFieldValue("slots[".concat(index, "].transferFE"), val);
                        setFieldTouched("transferFE", true);
                    } }),
                values.slots[index].isSubmitted && values.slots[index].event != '' && values.slots[index].minutes === '' ? (React.createElement("div", { className: "error" }, " Please select a minute ")) : null)));
    };
    return (React.createElement("div", { className: "ultra-static-wrapper" },
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, enableReinitialize: true, onSubmit: formSubmitHandler }, function (_a) {
                var _b;
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, "Partial Visit Transfer")),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))),
                    React.createElement("div", { className: "add-ultra-static-form form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement("h2", { className: "label-heading heading" }, "Lab Details")),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(text_filed_component_1.default, { name: "labCode", label: 'Lab Code', value: values === null || values === void 0 ? void 0 : values.labCode, isDisabled: true, placeholder: "Lab Code", required: true }))),
                            (values === null || values === void 0 ? void 0 : values.labName) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab Name', value: values === null || values === void 0 ? void 0 : values.labName, className: '' })),
                            (values === null || values === void 0 ? void 0 : values.labState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab State', value: values === null || values === void 0 ? void 0 : values.labState, className: '' })),
                            (values === null || values === void 0 ? void 0 : values.labCity) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab City', value: values === null || values === void 0 ? void 0 : values.labCity, className: '' }))),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement("h2", { className: "label-heading heading" }, "FE Details")),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(text_filed_component_1.default, { name: "feMobile", label: 'FE Mobile Number', value: values === null || values === void 0 ? void 0 : values.feMobile, placeholder: "FE Mobile Number", required: true, isDisabled: true }))),
                            (values === null || values === void 0 ? void 0 : values.feCode) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Code', value: values === null || values === void 0 ? void 0 : values.feCode })),
                            (values === null || values === void 0 ? void 0 : values.feName) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Name', value: values === null || values === void 0 ? void 0 : values.feName })),
                            (values === null || values === void 0 ? void 0 : values.feWorkingState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Working State', value: values === null || values === void 0 ? void 0 : values.feWorkingState })),
                            (values === null || values === void 0 ? void 0 : values.feWorkingState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Working City', value: values === null || values === void 0 ? void 0 : values.feWorkingState })))),
                    React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                        React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                            React.createElement("h2", { className: "ultra-static-subheading" }, "Manage Center Visits"))),
                    React.createElement("div", { className: "add-ultra-static-form form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, style: { marginTop: '-9px', marginBottom: '-9px' } },
                                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "sceduleType", value: values === null || values === void 0 ? void 0 : values.scheduleType, dropDownList: (ultrastatic_schedule_types === null || ultrastatic_schedule_types === void 0 ? void 0 : ultrastatic_schedule_types.length) > 0 ? ultrastatic_schedule_types : [], onChange: function (val) {
                                        setFieldValue('scheduleType', val);
                                        fetchSelectedFERoute(state.from_fe_id.id, val.value, setFieldValue);
                                    }, placeholder: "Schedule Type" }))),
                        (values === null || values === void 0 ? void 0 : values.slots.length) > 0 &&
                            React.createElement("div", { className: "field-array-wrapper" }, (_b = values === null || values === void 0 ? void 0 : values.slots) === null || _b === void 0 ? void 0 : _b.map(function (p, index) {
                                return (React.createElement("div", { key: p.id }, timeSlots(values, setFieldValue, setFieldTouched, errors, touched, index)));
                            })))));
            }))));
};
exports.default = PartialVisitsTransfer;

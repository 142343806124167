"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var schema_1 = require("../../../../constants/schema");
var array_helpers_1 = require("../../../../utils/array_helpers");
var CC_helper_1 = require("../../CC/CC_helper");
var cre_api_calls_1 = require("../cre_api_calls");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var CrePopUp = function (_a) {
    var openPopToAdd = _a.openPopToAdd, setPageload = _a.setPageload, editData = _a.editData, editListUpdater = _a.editListUpdater, callBackToLoadlist = _a.callBackToLoadlist, setPopToAdd = _a.setPopToAdd;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var addCre = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        labcode: '',
        axcode: '',
        email: '',
        id: '',
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.cre);
    var _b = React.useState(false), openPop = _b[0], setOpenPop = _b[1];
    var _c = React.useState(false), flags = _c[0], setFlags = _c[1];
    var _d = React.useState(null), getEditdata = _d[0], setEditdata = _d[1];
    var _e = React.useState(null), creAlredyexits = _e[0], setCreAlredyexits = _e[1];
    var lablistInformation = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var handleClosePop = function () {
        setOpenPop(false);
        setPopToAdd(false);
    };
    var formSubmitHandler = function (values) {
        var _a;
        var payload = {};
        payload.ax_code = values.axcode.toLocaleUpperCase();
        payload.first_name = values.firstName.toLocaleUpperCase();
        payload.last_name = values.lastName.toLocaleUpperCase();
        payload.lab_code = (_a = values === null || values === void 0 ? void 0 : values.labcode) === null || _a === void 0 ? void 0 : _a.value;
        payload.mobile = values.mobileNumber;
        payload.email = values.email;
        if (getEditdata == null) {
            (0, cre_api_calls_1.createCre)(payload)
                .then(function (response) {
                react_hot_toast_1.default.success(response);
                handleClosePop();
                setFlags(!flags);
                setPageload(!flags);
                setCreAlredyexits(null);
            })
                .catch(function (error) {
                var errorMessage = 'Cre already existed ';
                if (error.message == errorMessage) {
                    setCreAlredyexits('Ax Code Already in use');
                }
                else
                    react_hot_toast_1.default.error(error.message);
            });
        }
        if (getEditdata !== null) {
            payload.id = values === null || values === void 0 ? void 0 : values.id;
            (0, cre_api_calls_1.updateCre)(payload)
                .then(function (response) {
                react_hot_toast_1.default.success(response);
                setOpenPop(false);
                callBackToLoadlist(true);
            })
                .catch(function (e) {
                react_hot_toast_1.default.error(e.message);
            });
        }
    };
    var mobileNumberValidation = function (e, value, errors, setErrors) {
        var payload = {
            mobile: value,
        };
        (0, cre_api_calls_1.creMobileExist)(payload).then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.data.is_exist) {
                    setErrors(__assign(__assign({}, errors), { mobileNumber: "Mobile number is  already in use" }));
                }
            }
        });
    };
    React.useEffect(function () {
        setOpenPop(openPopToAdd);
    }, [openPopToAdd]);
    React.useEffect(function () {
        if (editData) {
            var lab = labList.filter(function (labs) { return labs.value === (editData === null || editData === void 0 ? void 0 : editData.lab_code); });
            setEditdata({
                firstName: editData === null || editData === void 0 ? void 0 : editData.first_name,
                lastName: editData === null || editData === void 0 ? void 0 : editData.last_name,
                mobileNumber: editData === null || editData === void 0 ? void 0 : editData.mobile,
                labcode: lab.length > 0 ? (0, CC_helper_1.convertEditDataForLabCode)(lab[0].value, lablistInformation) : '',
                axcode: editData === null || editData === void 0 ? void 0 : editData.ax_code,
                email: editData === null || editData === void 0 ? void 0 : editData.email,
                id: editData === null || editData === void 0 ? void 0 : editData.id,
            });
            setOpenPop(true);
        }
        else {
            setEditdata(null);
        }
    }, [editListUpdater]);
    return (React.createElement("div", null,
        React.createElement(pop_up_1.default, { open: openPop, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, getEditdata !== null ? 'Edit  CRE' : ' Create CRE')),
            React.createElement("div", { className: "modal-body" },
                React.createElement(formik_1.Formik, { enableReinitialize: true, initialValues: __assign({}, (getEditdata ? getEditdata : addCre)), validationSchema: schema_1.schemas.Add_cre_schema, validateOnBlur: false, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, setErrors = _a.setErrors;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        React.createElement(material_1.Grid, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "firstName", onChange: function (event) {
                                        setFieldValue('firstName', event.trim());
                                        setFieldTouched('firstName', true);
                                    }, label: "First Name ", value: values.firstName }),
                                errors['firstName'] && touched['firstName'] ? (React.createElement("div", { className: "error" }, errors['firstName'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "lastName", onChange: function (event) {
                                        setFieldValue('lastName', event.trim());
                                        setFieldTouched('lastName', true);
                                    }, label: "Last Name", value: values.lastName }),
                                errors['lastName'] && touched['lastName'] ? (React.createElement("div", { className: "error" }, errors['lastName'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "email", onChange: function (event) {
                                        setFieldValue('email', event);
                                        setFieldTouched('email', true);
                                    }, label: "Email", value: values.email }),
                                errors['email'] && touched['email'] ? (React.createElement("div", { className: "error" }, errors['email'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "axcode", onChange: function (event) {
                                        setFieldValue('axcode', event);
                                        setFieldTouched('axcode', true);
                                    }, label: "AX Code ", value: values.axcode, isDisabled: getEditdata !== null ? true : false }),
                                errors['axcode'] && touched['axcode'] ? (React.createElement("div", { className: "error" }, errors['axcode'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "mobileNumber", onChange: function (val) {
                                        setFieldValue('mobileNumber', val);
                                        setFieldTouched('mobileNumber', true);
                                        if (val.length === 10) {
                                            mobileNumberValidation(event, val, errors, setErrors);
                                        }
                                    }, onKeyPress: function (e) {
                                        if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                            e.target.value.length <= 9) {
                                        }
                                        else {
                                            e.preventDefault();
                                        }
                                    }, label: "Mobile Number ", value: values.mobileNumber, isDisabled: !features[menu_constants_1.FEATURE_CONSTANTS.CRE_EDIT_NUMBER] }),
                                errors['mobileNumber'] && touched['mobileNumber'] ? (React.createElement("div", { className: "error" }, errors['mobileNumber'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(drop_down_component_1.default, { required: true, name: "labcode", placeholder: "Lab Code", dropDownList: lablistInformation, onChange: function (val) {
                                        setFieldValue('labcode', val);
                                        setFieldTouched('labcode', true);
                                    }, value: values.labcode }),
                                errors['labcode'] &&
                                    touched['labcode'] &&
                                    values['labcode'] === '' ? (React.createElement("div", { className: "error" }, errors['labcode'])) : null,
                                creAlredyexits ? (React.createElement("div", { className: "error" }, creAlredyexits)) : null)),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { onClickHandler: handleClosePop, buttonName: "Cancel", color: "yellow-outline" }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
                })))));
};
exports.default = CrePopUp;

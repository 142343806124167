"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
require("moment-range");
var moment_range_1 = require("moment-range");
var React = require("react");
var react_1 = require("react");
var react_daterange_picker_1 = require("react-daterange-picker");
require("react-daterange-picker/dist/css/react-calendar.css");
require("./date-range-picker.scss");
var text_filed_component_1 = require("./text_filed_component");
var range = (0, moment_range_1.extendMoment)(moment).range;
var Text_filed_with_fromto_Daterange = function (_a) {
    var onChangeHandler = _a.onChangeHandler, minimumDate = _a.minimumDate, maximumDate = _a.maximumDate, required = _a.required, disabled = _a.disabled, show = _a.show, presentDate = _a.presentDate, activeTab = _a.activeTab, isDateRangeSelcted = _a.isDateRangeSelcted, startDate = _a.startDate, endDate = _a.endDate, editValue = _a.editValue;
    var today = moment();
    console.log(range(today.clone(), today.clone()), 'jgkgbljh');
    var start = moment(startDate);
    var end = moment(endDate);
    var selectedDateRange = range(start.clone(), end.clone());
    var _b = (0, react_1.useState)({
        isOpen: false,
        value: presentDate
            ? isDateRangeSelcted
                ? selectedDateRange
                : range(moment().startOf('day'), moment().endOf('day'))
            : null,
    }), dateRange = _b[0], setDateRange = _b[1];
    var selectDateHandler = function (value, states) {
        setDateRange(__assign(__assign({}, dateRange), { value: value }));
        onChangeHandler(value);
        console.log(value, 'value');
    };
    var calanderOCOnToggleHandler = function () {
        setDateRange(__assign(__assign({}, dateRange), { isOpen: !dateRange.isOpen }));
    };
    var onMouseLeaveCalanderCloseHandler = function (value) {
        setDateRange({
            isOpen: false,
            value: dateRange.value,
        });
    };
    var callBackToEmptyDate = function (e) {
        setDateRange(__assign(__assign({}, dateRange), { value: (dateRange.value = null), isOpen: (dateRange.isOpen = true) }));
        onChangeHandler(null);
    };
    return (React.createElement("div", { className: "date-rangepicker-wrapper" },
        React.createElement("div", { className: "date-range-input-wrapper", onClick: calanderOCOnToggleHandler },
            React.createElement(text_filed_component_1.default, { isDisabled: disabled, label: "Select Date Range", required: required, type: "button", value: editValue ? editValue :
                    dateRange.value &&
                        "".concat(dateRange.value.start.format('DD/MM/YYYY'), "-").concat(dateRange.value.end.format('DD/MM/YYYY')), placeholder: "Select Date Range", FormHelperTextProps: onclick }),
            dateRange.value && !disabled && (React.createElement("span", { onClick: callBackToEmptyDate, className: "clear-date icon-close" }))),
        React.createElement("div", { onMouseLeave: onMouseLeaveCalanderCloseHandler, className: "date-range-dropdown" }, dateRange.isOpen && !disabled && (React.createElement(react_daterange_picker_1.default, { key: activeTab, value: editValue && editValue.value ? editValue.value : dateRange.value, onSelect: selectDateHandler, singleDateRange: true, selectionType: 'range', minimumDate: minimumDate, maximumDate: maximumDate })))));
};
exports.default = Text_filed_with_fromto_Daterange;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var ETRF_apicalls_1 = require("../ETRF_apicalls");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var react_redux_1 = require("react-redux");
var moment = require("moment");
var react_hot_toast_1 = require("react-hot-toast");
var pop_up_1 = require("../../../../common/modal/pop-up");
var config_action_1 = require("../../../../actions/config_action");
var filter_actions_1 = require("../../../../actions/filter_actions");
var searchFilter_1 = require("../../../../common/search/searchFilter");
var ETRFList = function () {
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var etrfFilters = (0, react_redux_1.useSelector)(function (state) { return state.filtersReducer.etrfFilters; });
    var companiesList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList.company_names; });
    var searchTerm = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.searchTerm) ? etrfFilters.searchTerm : '';
    var PageLimit = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.PageLimit) ? etrfFilters.PageLimit : appConstants_1.defaultPageLimit;
    var selectedLab = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.labCode) ? etrfFilters.labCode : [];
    var startDate = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.fromDate) ? etrfFilters.fromDate : new Date().toString();
    var endDate = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.toDate) ? etrfFilters.toDate : new Date().toString();
    var company = etrfFilters && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.company) ? etrfFilters.company : '';
    var _a = React.useState(undefined), etrfList = _a[0], setETRFList = _a[1];
    var _b = React.useState(1), pageCount = _b[0], setpageCount = _b[1];
    var _c = React.useState(0), totalPageCount = _c[0], setTotalPageCount = _c[1];
    var _d = React.useState(0), totalCount = _d[0], setTotalCount = _d[1];
    var _e = React.useState(''), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = React.useState(false), downloadOpen = _f[0], setDownloadOpen = _f[1];
    var _g = React.useState(false), isDateRangeNull = _g[0], setIsDateRangeNull = _g[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments);
    var dispatchFunction = function (field, value) {
        dispatch((0, filter_actions_1.store_etrf_filters)({
            searchTerm: field === 'searchTerm' ? value : searchTerm,
            PageLimit: field === 'pageLimit' ? value : PageLimit,
            labCode: field === 'labCode' ? value : selectedLab,
            company: field === 'company' ? value : company,
            fromDate: field === 'dateRange' ? value.fromDate : startDate,
            toDate: field === 'dateRange' ? value.toDate : endDate,
        }));
    };
    var clickRow = function (event) {
        var evenType = event.event.target.className;
        if (features[menu_constants_1.FEATURE_CONSTANTS.ETRFView]) {
            if (evenType.includes('icon-Vector')) {
                nav("/".concat(route_path_1.default.PATHS.ETRFList, "/").concat(route_path_1.default.PATHS.ETRF_Profile), { state: event.data });
            }
        }
    };
    var searchingHandler = function (e) {
        dispatchFunction('searchTerm', e);
    };
    var handlePageLimitChange = function (val) {
        dispatchFunction('pageLimit', val);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = "page=".concat(currentPage, "&size=").concat(PageLimit ? parseInt(PageLimit.value.toString()) : 50);
        if (!isDateRangeNull) {
            if (startDate && endDate) {
                payload += "&start_date=".concat(moment(startDate).format('YYYY-MM-DD'), "&end_date=").concat(moment(endDate).format('YYYY-MM-DD'));
            }
        }
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.trim().length) >= 3) {
            payload += "&lab_number=".concat(searchTerm.toLocaleLowerCase().trim());
        }
        if (company) {
            payload += "&data_area_id=".concat(company.label);
        }
        if ((selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) > 0) {
            var labCodes_1 = '';
            selectedLab.forEach(function (labDetails, index) {
                if (selectedLab.length - 1 === index) {
                    labCodes_1 += "".concat(labDetails.value);
                }
                else {
                    labCodes_1 += "".concat(labDetails.value, ",");
                }
            });
            payload += "&lab_code=".concat(labCodes_1);
        }
        var isError = false;
        if (!searchTerm && selectedLab.length < 1 && isDateRangeNull) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
        }
        else if (!searchTerm && !startDate && !endDate && selectedLab.length > 0) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
            isError = true;
        }
        else if (searchTerm && !startDate && !endDate && selectedLab.length < 1) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && startDate && endDate && selectedLab.length > 0) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && !startDate && !endDate && selectedLab.length > 0) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && startDate && endDate && selectedLab.length < 1) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (!searchTerm && !startDate && !endDate && selectedLab.length < 1) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
            isError = true;
        }
        if (!isError) {
            (0, ETRF_apicalls_1.getETRFList)(payload)
                .then(function (response) {
                var pageSize = PageLimit
                    ? parseInt(PageLimit.value.toString())
                    : 50;
                var count = Math.ceil(response.data.count / pageSize);
                setErrorMessage("");
                setTotalPageCount(count);
                setETRFList((0, array_helpers_1.convertETRFResponse)(response.data.result));
                setTotalCount(response.data.count);
            })
                .catch(function (err) {
                setErrorMessage(err.message);
                setETRFList([]);
            });
        }
    }).refetch;
    var labHandler = function (value) {
        dispatchFunction('labCode', value);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            var fromDate = moment(dateRangeArray.start, 'YYYY-MM-DD');
            var toDate = moment(dateRangeArray.end, 'YYYY-MM-DD');
            var dayDiff = moment.duration(fromDate.diff(toDate)).asDays();
            if (Number(dayDiff.toString().split('-')[1]) > 4) {
                react_hot_toast_1.default.error('Date range cannot exceed 5 days', {
                    duration: 3000,
                });
            }
            else {
                var dateRange = {
                    fromDate: moment(dateRangeArray.start).format('YYYY-MM-DD'),
                    toDate: moment(dateRangeArray.end).format('YYYY-MM-DD')
                };
                dispatchFunction('dateRange', dateRange);
            }
            setIsDateRangeNull(false);
        }
        else {
            var dateRange = {
                fromDate: null,
                toDate: null
            };
            dispatchFunction('dateRange', dateRange);
            setIsDateRangeNull(true);
        }
    };
    var companyOnchangeHandler = function (val) {
        dispatchFunction('company', val);
    };
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var defaultLimit = 50;
        var payload = {
            page: currentPage.toString(),
            size: PageLimit ? PageLimit.value.toString() : defaultLimit.toString()
        };
        if (!isDateRangeNull) {
            if (startDate && endDate) {
                payload['start_date'] = moment(startDate).format('YYYY-MM-DD');
                payload['end_date'] = moment(endDate).format('YYYY-MM-DD');
            }
        }
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.trim().length) >= 3) {
            payload['lab_number'] = searchTerm.toLocaleLowerCase().trim();
        }
        if (company) {
            payload['data_area_id'] = company.label;
        }
        if ((selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) > 0) {
            var labCodes_2 = [];
            selectedLab.forEach(function (labDetails, index) {
                labCodes_2.push(labDetails.value);
            });
            payload['lab_code'] = labCodes_2;
        }
        var isError = false;
        if (!searchTerm && !selectedLab && isDateRangeNull) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
        }
        else if (!searchTerm && !startDate && !endDate && selectedLab) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
            isError = true;
        }
        else if (searchTerm && !startDate && !endDate && !selectedLab) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && startDate && endDate && selectedLab) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && !startDate && !endDate && selectedLab) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (searchTerm && startDate && endDate && !selectedLab) {
            if (searchTerm.length <= 8) {
                react_hot_toast_1.default.error('Lab number must be atleast 9 characters', { duration: 2000 });
                isError = true;
            }
        }
        else if (!searchTerm && !startDate && !endDate && !selectedLab && isDateRangeNull) {
            react_hot_toast_1.default.error('Please Select a date range', { duration: 2000 });
            isError = true;
        }
        if (!isError) {
            (0, ETRF_apicalls_1.downloadETRF)(payload)
                .then(function (response) {
                if (response.message != 'Success') {
                    react_hot_toast_1.default.error(response.message);
                }
                else {
                    setDownloadOpen(true);
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message);
            });
        }
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    React.useEffect(function () {
        refetch();
    }, []);
    return (React.createElement("div", { className: "page-wrapper etrf-docs-page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "e-TRF Documents")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, endIcon: 'icon-import download-icon', color: 'yellow-outline', onClickHandler: autoGenerateReportHandler, isError: !features[menu_constants_1.FEATURE_CONSTANTS.ETRFDownload] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(searchFilter_1.default, { placeholder: "Search By Labnumber", onChange: searchingHandler, value: searchTerm })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChangeHnadlre: labHandler, value: selectedLab }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true, editValue: (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.fromDate) && (etrfFilters === null || etrfFilters === void 0 ? void 0 : etrfFilters.toDate) ? "".concat(moment(etrfFilters.fromDate).format('DD/MM/YYYY'), "-").concat(moment(etrfFilters.toDate).format('DD/MM/YYYY')) : '' }),
                React.createElement(drop_down_component_1.default, { dropDownList: (companiesList === null || companiesList === void 0 ? void 0 : companiesList.length) > 0 ? companiesList : [], onChange: companyOnchangeHandler, value: company, placeholder: 'Data Area ID', disableClearable: true }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: function () { return refetch(); } }))),
        React.createElement("div", { className: "scrollable-content ".concat(features[menu_constants_1.FEATURE_CONSTANTS.ETRFView] ? 'clickable-row' : 'unclickablerow') },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ETRF_UPDATED_COLUMNS, rows: etrfList, errorMessage: errorMessage, multiSelector: "multiselect", onRowClicked: clickRow, pagination: false, className: 'etrf-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: etrfList === null || etrfList === void 0 ? void 0 : etrfList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: downloadOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setDownloadOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = ETRFList;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.persistConfig = exports.rootReducer = void 0;
var redux_1 = require("redux");
var storage_1 = require("redux-persist/lib/storage");
var FE_creation_reducer_1 = require("./FE_creation_reducer");
var config_reducers_1 = require("./config_reducers");
var loader_reducer_1 = require("./loader_reducer");
var user_reducer_1 = require("./user_reducer");
var toaster_reducer_1 = require("./toaster_reducer");
var filter_reducer_1 = require("./filter_reducer");
var rootReducer = (0, redux_1.combineReducers)({
    user: user_reducer_1.default,
    loader: loader_reducer_1.default,
    FE_creation_reducer: FE_creation_reducer_1.FE_creation_reducer,
    appConfigList: config_reducers_1.appConfigReducer,
    ToastReducer: toaster_reducer_1.ToastReducer,
    filtersReducer: filter_reducer_1.default
});
exports.rootReducer = rootReducer;
var persistConfig = {
    key: 'root',
    storage: storage_1.default,
    blacklist: ['loader', 'ToastReducer'],
    timeout: null,
};
exports.persistConfig = persistConfig;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputAdornment_1 = require("@mui/material/InputAdornment");
var TextField_1 = require("@mui/material/TextField");
var React = require("react");
require("./search.scss");
var SearchFilterComponent = function (_a) {
    var placeholder = _a.placeholder, onChange = _a.onChange, isDisabled = _a.isDisabled, keypressHandler = _a.keypressHandler, activetab = _a.activetab, className = _a.className, value = _a.value;
    var _b = React.useState(''), textFieldval = _b[0], setTextFieldVal = _b[1];
    var onChangeHandler = function (e) {
        setTextFieldVal(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };
    React.useEffect(function () {
        setTextFieldVal(value);
    }, [value]);
    var params = [];
    if (value) {
        params.push({ label: value });
    }
    return (React.createElement("div", { className: "search-wrapper" },
        React.createElement(TextField_1.default, { disabled: isDisabled, value: textFieldval, className: className, key: activetab, onChange: onChangeHandler, placeholder: placeholder, onKeyPress: keypressHandler, InputProps: {
                startAdornment: (React.createElement(InputAdornment_1.default, { position: "start" },
                    React.createElement("span", { className: "icon-search" }))),
            } })));
};
exports.default = SearchFilterComponent;
